import { Box, Dialog } from '@mui/material';
import { max } from 'lodash-es';
import { CSSProperties, MutableRefObject, RefObject } from 'react';

const popUpStyle: CSSProperties = {
  position: 'absolute',
  zIndex: 1300,
  fontFamily: 'Urbanist, sans-serif',
  fontSize: '18px',
  fontWeight: 600,
  backgroundColor: '#DCBB8B',
  border: '2px solid #E6D4AF',
  outline: '2px solid #AF8B68',
  backgroundImage: 'none',
  overflow: 'hidden',
  padding: 1,
  borderRadius: '10px',
  minWidth: '50px',
  maxWidth: '400px',
  width: 'max-content',
  wordWrap: 'break-word',
  wordBreak: 'keep-all',
  whiteSpace: 'normal',
  // textAlign: 'center'
};

interface InGamePopUpProps {
  children: React.ReactNode;
  popUpRef: RefObject<HTMLDivElement>;
}

export const InGamePopUp = ({ children, popUpRef }: InGamePopUpProps) => {
  return (
    <div id="in-game-pop-up" ref={popUpRef} style={popUpStyle}>
      {children}
    </div>
  );
};

export const calculatePosition = (
  mapBounds: { width: number; height: number },
  popUpRef: MutableRefObject<HTMLDivElement | null>,
  playerObject: { transform: { x: number; y: number } }
) => {
  setTimeout(() => {
    if (popUpRef.current) {
      const playerX = playerObject.transform.x;
      const playerY = playerObject.transform.y;
      const popUpElement = popUpRef.current;
      let right: string | undefined;
      let left: string | undefined;

      if (playerX <= 2) {
        left = '0px';
        popUpElement.style.left = `${left}`;
      } else if (playerX >= mapBounds.width - 2) {
        right = '-30px';
        popUpElement.style.right = `${right}`;
      } else {
        popUpElement.style.left = '25px';
        popUpElement.style.transform = 'translateX(-50%)';
      }
      if (playerY >= mapBounds.height - 2) {
        popUpElement.style.top = '-50px';
      } else {
        popUpElement.style.bottom = '5px';
      }
    }
  }, 0);
};

interface GameBackdropProps {
  open: boolean;
}

export const GameBackdrop = ({ open }: GameBackdropProps) => {
  return (
    <Dialog
      open={open}
      container={() => document.getElementById('game-canvas')}
      sx={{ position: 'absolute' }}
      PaperProps={{ style: { display: 'none' } }}
      slots={{
        backdrop: (props) => (
          <div
            {...props}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
          />
        )
      }}
    />
  );
};

interface MessageBoxProps {
  children: React.ReactNode;
}

export const MessageBox = ({ children }: MessageBoxProps) => {
  return (
    <Box
      component="div"
      sx={{
        px: 1,
        py: 1,
        background: '#6B4A5B',
        position: 'relative',
        overflow: 'visible',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      {/* Left Border */}
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: '-11px',
          left: '-8px',
          width: '8px',
          height: 'calc(100% + 22px)',
          backgroundColor: '#AF8B68',
          border: '1px solid #8A655F',
          borderRadius: '4px',
          zIndex: 2
        }}
      />

      {/* Right Border */}
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: '-11px',
          right: '-8px',
          width: '8px',
          height: 'calc(100% + 22px)',
          backgroundColor: '#AF8B68',
          border: '1px solid #8A655F',
          borderRadius: '4px',
          zIndex: 2
        }}
      />

      {/* Top Border */}
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: '-8px',
          left: '-11px',
          height: '8px',
          width: 'calc(100% + 22px)',
          backgroundColor: '#AF8B68',
          border: '1px solid #8A655F',
          borderRadius: '4px',
          zIndex: 1
        }}
      />

      {/* Bottom Border */}
      <Box
        component="div"
        sx={{
          position: 'absolute',
          bottom: '-8px',
          left: '-11px',
          height: '8px',
          width: 'calc(100% + 22px)',
          backgroundColor: '#AF8B68',
          border: '1px solid #8A655F',
          borderRadius: '4px',
          zIndex: 1
        }}
      />
      <Box component="div">{children}</Box>
    </Box>
  );
};
