import { Modal, Box, Button, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useGenericModal } from '../context/GenericModalContext';
import { useLocaleContext } from '../context/LocaleContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  outline: 'none',
  textAlign: 'center'
};

export default function UpgradePlanModal() {
  const { openModal: open, setOpenModal } = useGenericModal('upgrade-plan-modal');

  const { getLocaleLink } = useLocaleContext();

  return (
    <Modal
      open={open}
      onClose={() => setOpenModal(false)}
      aria-labelledby="upgrade-plan-modal"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Typography variant="h6" sx={{ textAlign: 'center', py: 2 }}>
          Upgrade Plan to Continue
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          You’ve used all your attempts for the day. Upgrade your plan to get unlimited attempts each day.
        </Typography>
        <Box component="div" sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            href={getLocaleLink('/purchase')}
          >
            Upgrade
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
