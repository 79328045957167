import { useMutation, useQueryClient } from 'react-query';
import { ApiError, LevelService, LevelStatusUpdate } from '../client';
import { useContext } from 'react';
import { UserContext } from '../pages/App';
import { useParams } from 'react-router-dom';
import { useGenericModal } from '../context/GenericModalContext';

export default function useLevelCompletion() {
  const { courseSlug } = useParams();

  const queryClient = useQueryClient();
  const currentUser = useContext(UserContext);
  const { setOpenModal: setOpenSignInModal } = useGenericModal('sign-up-credits-modal');
  const { setOpenModal: setOpenUpgradePlanModal } = useGenericModal('upgrade-plan-modal');

  const updateLevelCompletion = useMutation(
    (updatedLevelStatus: LevelStatusUpdate) => {
      return LevelService.upsertLevelCompletionApiLevelUpdateSlugPost(
        courseSlug ?? 'python',
        updatedLevelStatus
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`courseData-${currentUser?.user_id}`);
      },
      onError: (error: ApiError) => {
        if (error.status === 403) {
          if (currentUser) {
            setOpenUpgradePlanModal(true);
          } else {
            setOpenSignInModal(true);
          }
        }
      }
    }
  );

  return { updateLevelCompletion };
}
