import { useEffect, useState } from 'react';
import Collider from '../@core/Collider';
import GameObject from '../@core/GameObject';
import Interactable from '../@core/Interactable';
import ScenePortal from '../@core/ScenePortal';
import Sprite from '../@core/Sprite';
import TileMap from '../@core/TileMap';
import Player from '../entities/Player';
import { mapHelper, Matrix } from '../@core/utils/mapHelper';
import Collectable from '../@core/Collectable';
import { LevelData, MapObject } from '../client';
import ItemsLayer from '../@core/ItemsLayer';
import BuildLayer from '../@core/BuildLayer';
import { SoundEventsLayer } from '../@core/SoundEventsLayer';
import LevelComplete from '../@core/LevelComplete';
import CombineLayer from '../@core/CombineLayer';
import useStudents from '../components/useStudents';

const isTileCollidable = (x: number, y: number, mapHeight: number, objects: MapObject[]) => {
  const pixelX = x * 16;
  const pixelY = (mapHeight - (y + 1)) * 16;

  for (const area of objects) {
    if (
      pixelX >= area.x &&
      pixelX < area.x + area.width &&
      pixelY >= area.y &&
      pixelY < area.y + area.height
    ) {
      return true;
    }
  }

  return false;
};

export default function GameScene({ levelData }: { levelData: LevelData }) {
  const { start, map: mapData } = levelData;
  const layers = mapHelper.getLayers(mapData);
  const collisionsLayer = mapHelper.getCollisionLayer(mapData);
  const mapBounds = { width: layers[0][0].length, height: layers[0].length };
  const [tilesetsFirstgid, setTilesetsFirstgid] = useState<{ [key: number]: any }>();
  const { currentStudent } = useStudents();

  useEffect(() => {
    if (mapData) {
      mapHelper.getTilesetMapperDynamicJsonImports(mapData.tilesets).then((tilesets) => {
        setTilesetsFirstgid(tilesets);
      });
    }
  }, [mapData]);

  const mapHeight = layers[0].length;

  const resolveMapTiles = () => {
    if (!tilesetsFirstgid) return null;
    return layers.map((mapData: Matrix, index: number) => {
      const spriteSheetData = mapHelper.resolveSpriteSheetData(mapData, tilesetsFirstgid);
      return spriteSheetData.map((sheetData: any) => {
        return sheetData.map((subSheetData: any) => {
          if (!subSheetData) return null;
          const [type, x, y, subSheet] = subSheetData;
          const key = `${x}-${y}`;
          const position = { x, y };
          const subSheetName = Object.keys(subSheet.sheet)[0];
          const isCollidable = isTileCollidable(x, y, mapHeight, collisionsLayer?.objects || []);
          switch (type) {
            default:
              return (
                <GameObject key={key} {...position} layer={subSheetName as any} layerOffset={index}>
                  {isCollidable && <Collider />}
                  <Sprite {...subSheet} state={subSheetName} />
                </GameObject>
              );
          }
        });
      });
    });
  };

  const currentCharacter = currentStudent?.character || 'Rabbit';

  return (
    <>
      <GameObject name="map">
        <ambientLight intensity={3} />
        <CombineLayer levelData={levelData} />
        <ItemsLayer levelData={levelData} />
        <BuildLayer levelData={levelData} />
        <SoundEventsLayer />
        <LevelComplete />
        {tilesetsFirstgid && <TileMap data={layers[0]} resolver={resolveMapTiles} definesMapSize />}
      </GameObject>
      <GameObject x={16} y={5}>
        <Collider />
        <Interactable />
        <Collectable />
        <ScenePortal name="exit" enterDirection={[-1, 0]} target="other/start" />
      </GameObject>
      <Player
        gameObjectProps={{ x: start[0], y: start[1] }}
        character={currentCharacter}
        mapBounds={mapBounds}
        levelData={levelData}
      />
    </>
  );
}
