import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AuthService, ProfileService } from '../client';
import { useContext } from 'react';
import { UserContext } from '../pages/App';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocaleContext } from '../context/LocaleContext';

export default function useStudents() {
  const queryClient = useQueryClient();
  const currentUser = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { getLocaleLink } = useLocaleContext();

  const { data: students, isLoading: isStudentsLoading } = useQuery(
    [currentUser?.user_id, 'students'],
    () => ProfileService.getStudentsByUserApiProfileStudentsGet(),
    {
      enabled: !!currentUser,
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false
    }
  );
  const deleteStudentUserMutation = useMutation(
    ProfileService.deleteStudentApiProfileStudentsStudentIdDelete,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([currentUser?.user_id, 'students']);
        queryClient.invalidateQueries('students-limit');
      }
    }
  );

  const chooseStudent = useMutation(AuthService.chooseStudentApiAuthChooseStudentStudentIdPost, {
    onSuccess: (loginResponse) => {
      queryClient.invalidateQueries('currentUser');
      if (location.pathname.includes('/challenge')) {
        //TODO: Implement last played for challenges
        queryClient.invalidateQueries('credits-challenge');
        return;
      } else if (loginResponse.last_played) {
        document.location.href = getLocaleLink(
          `/play/${loginResponse.last_played.course_id}/${loginResponse.last_played.level_slug}`
        );
      } else {
        document.location.href = getLocaleLink('/');
      }
    }
  });

  const currentStudent = currentUser?.student_details;

  return { students, chooseStudent, isStudentsLoading, deleteStudentUserMutation, currentStudent };
}
