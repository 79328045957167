import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller } from 'react-hook-form';
import { CancelSubscriptionFeedbackSchema } from '../../client/models/CancelSubscriptionFeedbackSchema';
import { CancelSubscriptionReasonEnum } from '../../client/models/CancelSubscriptionReasonEnum';
import { useCancelSubscriptionFeedbackForm } from '../../hooks/useCancelSubscriptionFeedbackForm';

interface CancelSubscriptionFeedbackFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: CancelSubscriptionFeedbackSchema) => void;
  isProcessing: boolean;
}

const CancelSubscriptionFeedbackForm: React.FC<CancelSubscriptionFeedbackFormProps> = ({
  open,
  onClose,
  onSubmit,
  isProcessing
}) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors }
  } = useCancelSubscriptionFeedbackForm();

  // Watch the selected reason to conditionally show the feedback field
  const selectedReason = watch('cancel_subscription_reason');

  const handleFormSubmit = (formData: {
    cancel_subscription_reason: string;
    feedback?: string;
  }) => {
    const transformedData: CancelSubscriptionFeedbackSchema = {
      ...formData,
      cancel_subscription_reason:
        formData.cancel_subscription_reason as CancelSubscriptionReasonEnum,
      feedback: formData.feedback ?? ''
    };

    onSubmit(transformedData);
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ pb: 0 }}>Cancel Subscription</DialogTitle>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogContent>
          <Typography variant="body1" sx={{ pb: 2 }}>
            We're sorry to hear that you're considering canceling your subscription. Could you
            please share your feedback with us so we can improve our service? Your input is
            valuable, and we'd love to hear from you!
          </Typography>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Why do you want to cancel the subscription?</FormLabel>
            <Controller
              name="cancel_subscription_reason"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} aria-label="cancel_subscription_reason">
                  <FormControlLabel
                    value={CancelSubscriptionReasonEnum.FINISHED_COURSE}
                    control={<Radio />}
                    label="We finished the course"
                  />
                  <FormControlLabel
                    value={CancelSubscriptionReasonEnum.DONT_PLAY_ANYMORE}
                    control={<Radio />}
                    label="We don’t play anymore"
                  />
                  <FormControlLabel
                    value={CancelSubscriptionReasonEnum.NOT_ENOUGH_CONTENT}
                    control={<Radio />}
                    label="Not enough interesting content"
                  />
                  <FormControlLabel
                    value={CancelSubscriptionReasonEnum.ENGINE_NOT_WORKING}
                    control={<Radio />}
                    label="The game engine isn’t working well"
                  />
                  <FormControlLabel
                    value={CancelSubscriptionReasonEnum.TOO_EXPENSIVE}
                    control={<Radio />}
                    label="Too expensive"
                  />
                  <FormControlLabel
                    value={CancelSubscriptionReasonEnum.OTHER}
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              )}
            />
            {errors.cancel_subscription_reason && (
              <Typography color="error">{errors.cancel_subscription_reason.message}</Typography>
            )}
          </FormControl>

          {selectedReason === CancelSubscriptionReasonEnum.OTHER && (
            <Controller
              name="feedback"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Additional Feedback"
                  multiline
                  fullWidth
                  margin="normal"
                  rows={3}
                  error={!!errors.feedback}
                  helperText={errors.feedback?.message}
                />
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          <LoadingButton type="submit" variant="contained" color="primary" loading={isProcessing}>
            Cancel Subscription
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CancelSubscriptionFeedbackForm;
