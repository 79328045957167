import { Box } from '@mui/material';
import { LottieRefCurrentProps, useLottie } from 'lottie-react';
import QuestionCoinAnimation from '../resources/animations/question-coin.json';
import React, { useEffect } from 'react';

interface QuestionCoinAnimationViewProps {
  play: boolean;
}

const lottieStyle: { [key: string]: unknown } = {
  zIndex: 1400,
  maxWidth: '32px',
  height: '32px'
};

const QuestionCoinAnimationView = ({ play }: QuestionCoinAnimationViewProps) => {
  const lottieRef = React.useRef<LottieRefCurrentProps>(null);
  const { View: LottieView, goToAndPlay } = useLottie({
    animationData: QuestionCoinAnimation,
    loop: false,
    autoplay: true,
    style: lottieStyle,
    lottieRef
  });

  useEffect(() => {
    if (!play) return;

    goToAndPlay(0);
  }, [play]);

  return <Box component="div">{LottieView}</Box>;
};

export default QuestionCoinAnimationView;
