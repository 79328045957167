import { useContext } from 'react';
import PyodideContext, { PyodideContextType } from '../context/PyodideContext';

const usePyodide = (): Pick<
  PyodideContextType,
  | 'runScript'
  | 'isLoading'
  | 'errorAnnotations'
  | 'setErrorAnnotations'
  | 'errorMessage'
  | 'isCodeRunning'
  | 'setIsCodeRunning'
  | 'stopCodeExecution'
  | 'continueExecution'
  | 'postMessageToWorker'
> => {
  const context = useContext(PyodideContext);

  if (!context) {
    throw new Error(
      'Pyodide instance not found in the context. Make sure you are using PyodideProvider.'
    );
  }

  return {
    runScript: context.runScript,
    isLoading: context.isLoading,
    errorAnnotations: context.errorAnnotations,
    setErrorAnnotations: context.setErrorAnnotations,
    errorMessage: context.errorMessage,
    isCodeRunning: context.isCodeRunning,
    setIsCodeRunning: context.setIsCodeRunning,
    stopCodeExecution: context.stopCodeExecution,
    continueExecution: context.continueExecution,
    postMessageToWorker: context.postMessageToWorker
  };
};

export default usePyodide;
