import GameObject, { GameObjectProps } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useSceneManager from '../@core/useSceneManager';
import useGame from '../@core/useGame';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import { DidCollectEvent } from '../@core/Collectable';
import waitForMs from '../@core/utils/waitForMs';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import { useRef } from 'react';
import { checkDataIsEqual } from '../@core/utils/commandHelper';
import { useCommandError } from '../hooks/useCommandError';
import { XMARK_LIST } from '../@core/utils/xmarkList';

interface Reading {
  read: boolean;
}

function ReadScript() {
  const xmarkState = useRef(false);
  const { getRef } = useGameObject();
  const { publish, findGameObjectByName } = useGame();

  const { levelData, setLevelData } = useSceneManager();
  const { apiLevelData } = useApiLevelData();
  const { triggerError } = useCommandError();

  useGameObjectEvent<InteractionEvent>('interaction', async ({ obj, step }) => {
    const readStep = step as Reading;

    const { read } = readStep;
    if (!read) return;

    if (!apiLevelData) return;

    const { levelGoals } = apiLevelData;
    const { items } = apiLevelData;

    const readItems = items?.filter((item) => item.action === 'read') || [];
    const positionReadable = readItems.find(
      (item) => item.position[0] === obj.transform.x && item.position[1] === obj.transform.y
    );

    const levelReadables = levelGoals?.filter(
      (goal) =>
        goal.action === 'read' &&
        goal.position?.[0] === obj.transform.x &&
        goal.position?.[1] === obj.transform.y
    );

    const levelReadable =
      levelReadables.length > 1
        ? levelReadables.find((goal) => checkDataIsEqual(goal.data, positionReadable?.data))
        : levelReadables[0];

    if (!positionReadable) {
      triggerError(UNEXPECTED_ERRORS.read.position);
      return false;
    }

    // if (!levelReadable) {
    //   triggerError(UNEXPECTED_ERRORS.read.wrongMessage);
    //   return false;
    // }

    // if (!checkDataIsEqual(levelReadable.data, positionReadable?.data)) {
    //   triggerError(UNEXPECTED_ERRORS.read.data(levelReadable?.data));
    //   return false;
    // }

    const playerObject = findGameObjectByName('player');
    const playerMoveable = playerObject?.getComponent('Moveable');
    await playerMoveable.read({ read: true });

    const newLevelData = levelData.map((item) => {
      if (
        XMARK_LIST.includes(item.type || '') &&
        item.position?.[0] === obj.transform.x &&
        item.position?.[1] === obj.transform.y &&
        item.action === 'read'
      ) {
        return { ...item, score: (item.score += 1) };
      }
      return item;
    });

    setLevelData(newLevelData);

    publish<DidCollectEvent>('did-collect', newLevelData);

    xmarkState.current = !xmarkState.current;

    if (xmarkState.current) {
      getRef().setDisabled(true);
    }
    return waitForMs(400);
  });

  return null;
}

interface ItemReadProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemRead({ props, state, spriteData }: ItemReadProps) {
  const name = `${state}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Interactable type="read" />
      <ReadScript />
    </GameObject>
  );
}
