import GameObject, { GameObjectProps, Position } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useSceneManager from '../@core/useSceneManager';
import useGame from '../@core/useGame';
import { PubSubEvent } from '../@core/utils/createPubSub';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import { DidCollectEvent } from '../@core/Collectable';
import waitForMs from '../@core/utils/waitForMs';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import { useRef } from 'react';
import { Place } from '../@core/utils/movementHelpers';
import { useCommandError } from '../hooks/useCommandError';
import { checkDataIsEqual } from '../@core/utils/commandHelper';
import { useSound } from '../@core/Sound';
import soundData from '../soundData';
import { XMARK_LIST } from '../@core/utils/xmarkList';

export interface TypedPosition extends Position {
  positionType: 'horizontal' | 'vertical';
}

export type PlaceObjectEvent = PubSubEvent<'place-object', TypedPosition>;

const placeableData = ['trayFoodEmpty'];

function ItemPlaceScript() {
  const xmarkState = useRef(false);
  const { getRef } = useGameObject();
  const { publish, findCompoundObjectByXY, findGameObjectByName } = useGame();

  const { levelData, setLevelData, inventory, setInventoryData } = useSceneManager();
  const { apiLevelData } = useApiLevelData();

  const { triggerError } = useCommandError();
  const playPlaceItemSound = useSound(soundData.placeItem);
  const playPlaceFoodSound = useSound(soundData.placeFood);

  useGameObjectEvent<InteractionEvent>('interaction', async ({ obj, step }) => {
    const placeStep = step as Place;
    const place = placeStep.place;

    if (!place) return;

    if (!apiLevelData) return;

    const { levelGoals } = apiLevelData;

    const actionPoint = {
      x: getRef().transform.x,
      y: getRef().transform.y
    };
    const dirX = obj.transform.rotationX;
    const dirY = obj.transform.rotationY;

    const storagePosition = {
      x: actionPoint.x + dirX,
      y: actionPoint.y + dirY
    };

    const placeObjectGoals = levelGoals.filter(
      (goal) =>
        XMARK_LIST.includes(goal?.type || '') &&
        goal.action === 'place' &&
        goal?.position?.[0] === actionPoint.x &&
        goal?.position?.[1] === actionPoint.y
    );

    const placeObjectGoal = placeObjectGoals.find((goal) =>
      checkDataIsEqual(goal.data, placeStep.placeObject)
    );

    if (!placeObjectGoal) {
      triggerError(UNEXPECTED_ERRORS.place.object);
      return;
    }

    if (
      placeObjectGoal?.targetPosition?.[0] !== storagePosition.x ||
      placeObjectGoal?.targetPosition?.[1] !== storagePosition.y
    ) {
      triggerError(UNEXPECTED_ERRORS.place.position);
      return;
    }

    const placeablePart = findCompoundObjectByXY(
      placeObjectGoal?.targetPosition?.[0],
      placeObjectGoal?.targetPosition?.[1]
    );

    const placeableType = placeableData.find(
      (d) => placeablePart?.name?.startsWith(d) || placeablePart?.layer?.startsWith(d)
    );

    const inventoryItem = inventory.find((item) => item.type === placeObjectGoal?.cost?.type);

    const costAmount = placeObjectGoal?.cost?.amount || 0;

    const placeObject = placeStep.placeObject;
    const isPlaceObjectNumber = typeof placeObject === 'number';
    const isPlaceObjectString = typeof placeObject === 'string';

    const isNumberObjectPlaceable = isPlaceObjectNumber && placeObject === inventoryItem?.count;
    const isStringObjectPlaceable =
      isPlaceObjectString && checkDataIsEqual(placeObject, placeObjectGoal.data);

    if (isPlaceObjectNumber && !isNumberObjectPlaceable && costAmount !== 0) {
      triggerError(UNEXPECTED_ERRORS.place.number);
      return;
    }

    if (isPlaceObjectString && !isStringObjectPlaceable) {
      triggerError(UNEXPECTED_ERRORS.place.string);
      return;
    }

    if (
      isPlaceObjectString &&
      inventoryItem?.count !== placeObjectGoal?.cost?.amount &&
      costAmount !== 0
    ) {
      triggerError(UNEXPECTED_ERRORS.place.collect);
      return;
    }

    const newLevelData = levelData.map((item) => {
      if (
        XMARK_LIST.includes(item?.type || '') &&
        item.action === 'place' &&
        item.data === placeObjectGoal.data &&
        item.position?.[0] === actionPoint.x &&
        item.position?.[1] === actionPoint.y
      ) {
        return { ...item, score: (item.score += 1) };
      }
      return item;
    });

    setLevelData(newLevelData);

    if (placeableType) {
      const playerObject = findGameObjectByName('player');
      const playerInteractable = playerObject?.getComponent('Interactable');
      await playerInteractable.interact({
        position: { x: placeablePart?.transform.x, y: placeablePart?.transform.y },
        step: { state: 'fill' },
        command: `food-${placeableType}`
      });
      playPlaceFoodSound();
      await waitForMs(1000);
    }

    setInventoryData(
      inventory.map((item) => {
        if (item.type === inventoryItem?.type && isNumberObjectPlaceable) {
          return { ...item, count: 0 };
        } else if (item.type === inventoryItem?.type && isStringObjectPlaceable) {
          return { ...item, count: (item.count -= 1) };
        }
        return item;
      })
    );

    publish<DidCollectEvent>('did-collect', newLevelData);

    xmarkState.current = !xmarkState.current;

    if (xmarkState.current) {
      getRef().setDisabled(true);
    }
    if (!placeableType) {
      playPlaceItemSound();
    }
    return waitForMs(1200);
  });

  return null;
}

interface ItemPlaceProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemPlace({ props, state, spriteData, itemName }: ItemPlaceProps) {
  const name = `${state}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Interactable type="place" />
      <ItemPlaceScript />
    </GameObject>
  );
}
