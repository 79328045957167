import { CreditService, ExecutionType } from "../client";
import { useQuery } from "react-query";

export const useCredits = (executionType: ExecutionType) => {
  const result = useQuery(
    [`credits-${executionType}`],
    () => CreditService.getCreditApiCreditExecutionTypeGet(executionType),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return result;
};
