export const parseJson = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return json;
  }
};

export type Primitive = string | number;
export type JSONObject = { [key: string]: Primitive };
export type Data = Primitive | JSONObject | Primitive[];
export type DataType = 'primitive' | 'json' | 'list' | 'class' | 'unknown';

export const detectDataType = (data: Data): DataType => {
  if (typeof data === 'string' || typeof data === 'number') {
    return 'primitive';
  } else if (Array.isArray(data)) {
    return 'list';
  } else if (typeof data === 'object' && data !== null && !('__class__' in data)) {
    return 'json';
  } else if (typeof data === 'object' && data !== null) {
    return 'class';
  } else {
    return 'unknown';
  }
};

export const objectsAreEqual = (obj1: JSONObject, obj2: JSONObject) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

export const arraysAreEqual = (arr1: Primitive[], arr2: Primitive[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const item1 = arr1[i];
    const item2 = arr2[i];

    const areBothArrays = Array.isArray(item1) && Array.isArray(item2);

    if (areBothArrays) {
      if (!arraysAreEqual(item1 as Primitive[], item2 as Primitive[])) {
        return false;
      }
    } else if (item1 !== item2) {
      return false;
    }
  }

  return true;
};

export const checkDataIsEqual = (data1: Data, data2: Data) => {
  const type1 = detectDataType(data1);
  const type2 = detectDataType(data2);

  if (type1 !== type2) {
    return false;
  }

  switch (type1) {
    case 'primitive':
      return data1 === data2;
    case 'json':
      return objectsAreEqual(data1 as JSONObject, data2 as JSONObject);
    case 'list':
      return arraysAreEqual(data1 as Primitive[], data2 as Primitive[]);
    default:
      return false;
  }
};

export const convertToPrimitive = (data: Data): Primitive => {
  const type = detectDataType(data);
  switch (type) {
    case 'primitive':
      return data as Primitive;
    case 'json':
      return JSON.stringify(data, null, 2);
    case 'list':
      return (data as Primitive[]).join(',');
    default:
      return data as Primitive;
  }
};
