import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';
import { addCompleter } from 'ace-builds/src-noconflict/ext-language_tools';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useOutput } from '../context/OutputContext';
import { Button, Box, CircularProgress, Typography, Tooltip, Stack } from '@mui/material';

import { FileUpload, PlayArrow, Refresh, Save, Stop } from '@mui/icons-material';
import usePyodide from '../hooks/usePyodide';
import waitForMs from '../@core/utils/waitForMs';
import { useGenericModal } from '../context/GenericModalContext';
import { useCode } from '../context/CodeContext';
import SaveCodeModal from './SaveCodeModal';
import { CourseData, ExecutionType } from '../client';
import LoadCodeModal from './LoadCodeModal';
import { useParams } from 'react-router-dom';
import { Ace } from 'ace-builds';
import { UserContext } from '../pages/App';
import { useTipContext } from '../context/TipProvider';
import { useTranslation } from 'react-i18next';
import { useCredits } from '../hooks/useCredits';
import CreditCounter from './Challenge/CreditCounter';
import { useQueryClient } from 'react-query';
import { useVirtualAssistantContext } from '../context/VirtualAssistantContext';
import AssistantSwitch from './Assistant/Switcher';
import CodeAdderAssistant from './Assistant/CodeAdder';
import useLevels from './useLevels';
import SignUpCreditsModal from '../components/SignUpCreditsModal';
import UpgradePlanModal from '../components/UpgradePlanModal';
import { useCurrentUser } from '../hooks/useCurrentUser';
import Cookies from 'js-cookie';

interface CodeEditorProps {
  codeTemplate: string;
  setMobileToggle: React.Dispatch<React.SetStateAction<'code' | 'game'>>;
  courseData?: CourseData;
  onCodeExecution: () => Promise<void>;
  maxLinesOfCode?: number;
  chapterId: number;
}

const DEFAULT_LINE_HEIGHT = 22;

export default function CodeEditor({
  codeTemplate,
  setMobileToggle,
  courseData,
  onCodeExecution,
  maxLinesOfCode,
  chapterId
}: CodeEditorProps) {
  const userContext = useContext(UserContext);
  const [editor, setEditor] = useState<Ace.Editor>();
  const { levelSlug } = useParams();
  const editorRef = useRef<AceEditor>(null);

  const [tooltip, setTooltip] = useState({
    visible: false,
    text: '',
    x: 0,
    y: 0
  });
  const [tipLineNumber, setTipLineNumber] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const {
    generateTipLevelAsync,
    setTipVisible: setTipVisibleAssistant,
    tipVisible: tipVisibleAssistant,
    tip: tipAssistant,
    isTipSwitcherActive,
    setIsTipSwitcherActive,
    playerPosition,
    facingDirection
  } = useVirtualAssistantContext();
  const { code, setCode } = useCode();
  const { setOpenModal: setGoToSignUpModal } = useGenericModal('go-to-sign-up-modal');
  const { setOpenModal: setOpenSaveCodeModal } = useGenericModal('saveCode');
  const { setOpenModal: setOpenLoadCodeModal } = useGenericModal('loadCode');
  const { setOpenModal: setOpenErrorModal, setContent: setErrorModalContent } =
    useGenericModal('error');

  const { errorMessage } = usePyodide();

  const { levelData } = useLevels();
  const { currentUser, isCurrentUserLoading } = useCurrentUser();

  const { data: credits, isLoading: isCreditsLoading } = useCredits(ExecutionType.LEVEL);
  const { data: assistantCredits, isLoading: isAssistantCreditsLoading } = useCredits(
    ExecutionType.LEVEL_ASSISTANT
  );

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  useEffect(() => {
    if (courseData) {
      const chapterData = courseData.chapters[chapterId]?.level_data;
      if (chapterData) {
        const currentLevelData = chapterData.find((level) => level.slug === levelSlug);
        setCode(currentLevelData?.last_code_executed || codeTemplate);
      }
    }
  }, [codeTemplate, courseData, chapterId, levelSlug]);

  useEffect(() => {
    onCursorChange();
  }, [tipAssistant]);

  useEffect(() => {
    const editor = editorRef.current?.editor;
    if (editor) {
      editor.session.on('changeScrollTop', handleScroll);
      return () => {
        editor.session.off('changeScrollTop', handleScroll);
      };
    }
  }, [tooltip.y]);

  const onCursorChange = () => {
    if (!tipAssistant) return;

    const lineNumber = tipAssistant?.line_number || 0;
    setTipLineNumber(lineNumber);

    if (!editorRef.current) return;
    const editor = editorRef.current.editor;
    editor.resize(true);
    editor.renderer.setScrollMargin(0, 300, 0, 0);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    editor.renderer.scrollToLine(lineNumber, false, true, () => {});
    editor.gotoLine(lineNumber, 0, true);
    setTimeout(() => {
      // wait for the editor to scroll to the line
      const scrollTop = editor.getSession().getScrollTop();
      const coords = editor.renderer.textToScreenCoordinates(lineNumber, 0);
      setTipVisibleAssistant(true);
      setTooltip({
        visible: true,
        text: '',
        x: coords.pageX + 50,
        y: lineNumber * DEFAULT_LINE_HEIGHT - scrollTop - DEFAULT_LINE_HEIGHT
      });
    }, 500);
  };

  const {
    runScript,
    isLoading: isPyodideLoading,
    errorAnnotations,
    setErrorAnnotations,
    isCodeRunning,
    setIsCodeRunning,
    stopCodeExecution
  } = usePyodide();
  const {
    isCodeExecuted,
    setIsCodeExecuted,
    setReturnToStart,
    setResetLevel,
    firstExecution,
    setFirstExecution,
    commandsInQueue,
    resetCommandsQueue
  } = useOutput();

  const countNonCommentNonEmptyLines = (code: string): number => {
    return code.split('\n').filter((line) => line.trim() !== '' && !line.trim().startsWith('#'))
      .length;
  };

  useEffect(() => {
    if (!isCodeExecuted) return;
    setIsCodeRunning(commandsInQueue.length !== 0);
  }, [commandsInQueue]);

  const signUpModalLevels = ['intro-level5', 'intro-level6', 'intro-level7'];

  const runCode = async () => {
    if (isRunning || isExecutionDisabled) return;
    setIsRunning(true);
    setTooltip({ visible: false, text: '', x: 0, y: 0 });
    setTipVisibleAssistant(false);
    if (!levelData || isCurrentUserLoading) return;
    if (levelData.slug && signUpModalLevels.includes(levelData.slug) && !currentUser) {
      setGoToSignUpModal(true);
      return;
    }
    if (
      maxLinesOfCode !== undefined &&
      maxLinesOfCode !== null &&
      countNonCommentNonEmptyLines(code) > maxLinesOfCode
    ) {
      // TODO: think whether we need Virtual Assistant here
      setErrorModalContent(t('codeEditor.exceededMaxLines', { maxLines: maxLinesOfCode }));
      setOpenErrorModal(true);
      return;
    }

    setMobileToggle('game');
    setErrorAnnotations([]);
    try {
      await onCodeExecution();
    } catch (error) {
      // prevent code execution if there is an error
      return;
    }

    if (!firstExecution) {
      setResetLevel(true);
    } else {
      setFirstExecution(false);
    }
    setIsCodeExecuted(false);
    await waitForMs(200);
    setReturnToStart(true);
    await waitForMs(500);

    try {
      await runScript(code, true);
      queryClient.invalidateQueries('credits-level');
    } catch (error) {
      if (isTipSwitcherActive && levelData) {
        generateTipLevelAsync(
          levelData,
          playerPosition || [0, 0],
          facingDirection || [1, 0],
          errorMessage
        );
        queryClient.invalidateQueries('credits-level_assistant');
      }
      stopCodeExecution();
      console.error('Error running script:', error);
    }

    setIsCodeExecuted(true);
    setIsRunning(false);
  };

  const stop = () => {
    resetCommandsQueue();
    setIsCodeRunning(false);
    setIsCodeExecuted(true);
  };

  const onChange = (newValue: string) => {
    setCode(newValue);
  };

  const reset = () => {
    setCode(codeTemplate);
    setResetLevel(true);
  };

  const loadCode = (code: string) => {
    setCode(code);
    setOpenLoadCodeModal(false);
  };

  const handleActivateTipSwitcher = (value: boolean) => {
    setIsTipSwitcherActive(value);
    Cookies.set('isTipSwitcherActive', String(value));
  };

  useEffect(() => {
    if (!editor) return;
    editor.getSession().setAnnotations(errorAnnotations);
  }, [errorAnnotations]);

  useEffect(() => {
    // data stub:
    const sqlTables = [
      { name: 'move_forward()', description: 'Move forward' },
      { name: 'turn_left()', description: 'Turn left' },
      { name: 'turn_right()', description: 'Turn right' },
      { name: 'push()', description: 'Push an object' },
      { name: 'build()', description: 'Build an object' },
      { name: 'speak()', description: 'Speak text' },
      { name: 'open()', description: 'Open an object' },
      { name: 'close()', description: 'Close an object' },
      { name: 'water()', description: 'Water an object' },
      { name: 'collect()', description: 'Collect an object' },
      { name: 'place()', description: 'Place an object' },
      { name: 'combine()', description: 'Combine objects' }
    ];

    const sqlTablesCompleter = {
      getCompletions: (
        editor: Ace.Editor,
        session: Ace.EditSession,
        pos: Ace.Point,
        prefix: string,
        callback: Ace.CompleterCallback
      ): void => {
        callback(
          null,
          sqlTables.map(
            (table) =>
              ({
                caption: `${table.name}: ${table.description}`,
                value: table.name,
                meta: 'function'
              }) as Ace.Completion
          )
        );
      }
    };
    addCompleter(sqlTablesCompleter);
  }, []);

  if ((isPyodideLoading && !runScript) || !courseData) {
    return <CircularProgress />;
  }

  const isExecutionDisabled =
    !isCodeExecuted ||
    (userContext && !userContext?.student_id) ||
    isCodeRunning ||
    isPyodideLoading;

  const insertCode = (resultedCode: string) => {
    setCode(resultedCode);
    setTipVisibleAssistant(false);
    setTooltip({ visible: false, text: '', x: 0, y: 0 });
  };

  const handleScroll = () => {
    if (!editorRef.current || !tooltipRef.current) return;
    const editor = editorRef.current.editor;
    const scrollTop = editor.getSession().getScrollTop();
    const top = tipLineNumber * 22 - scrollTop - 22;
    tooltipRef.current.style.top = `${top}px`;
  };

  const assistantTitle = !isTipSwitcherActive ? 'Activate assistant' : 'Deactivate assistant';

  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', flex: 1, py: 2 }}>
      <Box
        component="div"
        sx={{ my: 2, ml: 5.5, display: 'flex', gap: 2, flexDirection: 'column' }}
      >
        <SignUpCreditsModal />
        <UpgradePlanModal />
        <Box component="div" justifyContent="space-between" sx={{ display: 'flex' }}>
          <Stack direction="row" sx={{ position: 'relative' }}>
            {isCodeRunning ? (
              <Button onClick={stop} variant="outlined" color="error" startIcon={<Stop />}>
                {/* TODO: add locales */}
                {/* {t('codeEditor.stop')} */}
                STOP
              </Button>
            ) : (
              <Box component="div" position="relative">
                <Button
                  onClick={runCode}
                  disabled={isExecutionDisabled || isRunning}
                  variant="contained"
                  startIcon={!isCodeRunning && <PlayArrow />}
                >
                  {!isCodeRunning && !isRunning ? (
                    t('codeEditor.runCode')
                  ) : (
                    <Box
                      component="div"
                      sx={{ display: 'flex', gap: 1, flexDirection: 'row', alignItems: 'center' }}
                    >
                      <CircularProgress size={15} />
                      {t('codeEditor.running')}
                    </Box>
                  )}
                </Button>
              </Box>
            )}
            <CreditCounter
              isCodeRunning={isCodeRunning}
              credits={credits}
              loading={isCreditsLoading}
            />
          </Stack>
          <Stack direction="row">
            <Stack direction="row">
              <Tooltip title={assistantTitle}>
                <Box component="div">
                  <AssistantSwitch
                    tipActivated={isTipSwitcherActive}
                    setTipActivated={handleActivateTipSwitcher}
                    isExecutionDisabled={isExecutionDisabled}
                  />
                </Box>
              </Tooltip>
              <CreditCounter
                isCodeRunning={isCodeRunning}
                credits={assistantCredits}
                loading={isAssistantCreditsLoading}
              />
            </Stack>
          </Stack>
        </Box>
        <Stack direction="row" gap={2}>
          <Button
            onClick={reset}
            variant="outlined"
            disabled={isExecutionDisabled}
            startIcon={<Refresh />}
          >
            {t('codeEditor.reset')}
          </Button>
          <Button
            onClick={() => {
              setOpenSaveCodeModal(true);
            }}
            disabled={isExecutionDisabled}
            variant="outlined"
            startIcon={<Save />}
          >
            {t('codeEditor.save')}
          </Button>
          <SaveCodeModal code={code} />
          <Button
            onClick={() => {
              setOpenLoadCodeModal(true);
            }}
            disabled={isExecutionDisabled}
            variant="outlined"
            startIcon={<FileUpload />}
          >
            {t('codeEditor.load')}
          </Button>
          <LoadCodeModal onLoad={loadCode} />
        </Stack>
      </Box>
      <Box component="div" sx={{ position: 'relative', overflow: 'none' }}>
        <Stack>
          <AceEditor
            ref={editorRef}
            onLoad={setEditor}
            width="100%"
            height="400px"
            fontSize={16}
            mode="python"
            theme="monokai"
            onChange={onChange}
            value={code}
            name="game-editor"
            // add completer: https://github.com/securingsincity/react-ace/issues/338
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              showLineNumbers: true,
              tabSize: 4
            }}
            scrollMargin={[0, 300, 0, 0]}
            editorProps={{ $blockScrolling: true }}
          />
          {tipVisibleAssistant && (
            <CodeAdderAssistant
              ref={tooltipRef}
              sx={{
                position: 'absolute',
                top: `${tooltip.y}px`,
                right: 0
              }}
              onClick={insertCode}
            />
          )}
        </Stack>
      </Box>
    </Box>
  );
}
