import { useState, useRef, useLayoutEffect } from 'react';
import HtmlOverlay from './HtmlOverlay';
import { SpeakingEvent } from './Moveable';
import useGameObjectEvent from './useGameObjectEvent';
import waitForMs from './utils/waitForMs';
import { Primitive } from './utils/commandHelper';
import { CSSProperties } from 'react';
import useGame from './useGame';
import { calculatePosition } from '../components/GameUI/InGamePopUp';

interface SpeechBubbleProps {
  mapBounds: { width: number; height: number };
}

export default function SpeechBubble({ mapBounds }: SpeechBubbleProps) {
  const [speakingPhrase, setSpeakingPhrase] = useState<Primitive | null>(null);
  const bubbleRef = useRef<HTMLDivElement>(null);
  const { findGameObjectByName } = useGame();

  useGameObjectEvent<SpeakingEvent>('speaking', async ({ phrase, isSpeaking }) => {
    if (!phrase) return;

    setSpeakingPhrase(phrase);

    const baseDuration = 2500;
    const additionalTimePerChar = 50;
    let duration = baseDuration;

    if (typeof phrase === 'string') {
      duration += Math.max(0, phrase.length - 10) * additionalTimePerChar;
    }

    await waitForMs(duration);
    setSpeakingPhrase(null);
    isSpeaking.current = false;
  });

  const bubbleStyle: CSSProperties = {
    position: 'absolute',
    zIndex: 5,
    fontFamily: 'Urbanist, sans-serif',
    fontSize: '18px',
    fontWeight: 600,
    backgroundColor: 'rgba(255,255,255,0.9)',
    padding: '10px',
    color: 'black',
    borderRadius: '10px',
    minWidth: '50px',
    maxWidth: '200px',
    width: 'max-content',
    wordWrap: 'break-word',
    wordBreak: 'keep-all',
    whiteSpace: 'normal',
    textAlign: 'center'
  };

  useLayoutEffect(() => {
    if (speakingPhrase) {
      calculatePosition(mapBounds, bubbleRef, findGameObjectByName('player'));
    }
  }, [speakingPhrase]);

  return (
    <>
      {speakingPhrase && (
        <HtmlOverlay>
          <div id="speech-bubble" ref={bubbleRef} style={bubbleStyle}>
            {speakingPhrase}
          </div>
        </HtmlOverlay>
      )}
    </>
  );
}
