import React, { useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import {
  PaymentService,
  FeedbackService,
  CancelSubscriptionFeedbackSchema,
  CancelSubscriptionReasonEnum
} from '../../client';
import { Loader } from '../Loader';
import useCheckout, { PADDLE_SUBSCRIPTION_PRICE_ID } from '../useCheckout';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { useStudentsByPurchase } from '../../hooks/useStudentsByPurchase';
import CancelSubscriptionFeedbackForm from '../Feedback/CancelSubscriptionFeedbackForm';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useCurrentSubscription } from '../../hooks/useCurrentSubscription';

export default function BillingDetails() {
  const queryClient = useQueryClient();
  const { openCheckout } = useCheckout();
  const { data: students, isLoading: isStudentsLoading } = useStudentsByPurchase();
  const [isCancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] = useState<boolean>(false);
  const [isCancelSubscriptionProcessing, setIsCancelSubscriptionProcessing] =
    useState<boolean>(false);
  const [isSubscriptionCancelled, setIsSubscriptionCancelled] = useState<boolean>(false);

  const { currentUser } = useCurrentUser();
  const { data: currentSubscription, isLoading: isSubscriptionLoading } = useCurrentSubscription();

  const { createSnackBar } = useSnackBarContext();

  // Mutation to send feedback
  const sendFeedbackMutation = useMutation(
    FeedbackService.sendCancelSubscriptionFeedbackApiFeedbackCancelSubscriptionPost
  );

  // Mutation to cancel the subscription
  const cancelSubscriptionMutation = useMutation(
    PaymentService.cancelSubscriptionApiPaymentCancelSubscriptionPost,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('current-subscription');
        createSnackBar({
          content: 'Subscription cancelled successfully',
          severity: 'success',
          autoHide: true
        });
        setCancelSubscriptionModalOpen(false);
        setIsSubscriptionCancelled(true);
      },
      onError: () => {
        createSnackBar({
          content: 'Failed to cancel subscription',
          severity: 'error',
          autoHide: true
        });
      }
    }
  );

  const onSubmitCancelSubscriptionFeedback = async (data: any) => {
    try {
      setIsCancelSubscriptionProcessing(true);
      const transformedData: CancelSubscriptionFeedbackSchema = {
        ...data,
        cancel_subscription_reason: data.cancel_subscription_reason as CancelSubscriptionReasonEnum
      };

      await sendFeedbackMutation.mutateAsync(transformedData);
      await cancelSubscriptionMutation.mutateAsync();
    } catch (error) {
      createSnackBar({
        content: 'Failed to cancel subscription',
        severity: 'error',
        autoHide: true
      });
    } finally {
      setIsCancelSubscriptionProcessing(false);
    }
  };

  const handleCancelSubscriptionModal = () => {
    setCancelSubscriptionModalOpen(true);
  };

  if (isSubscriptionLoading)
    return (
      <Paper sx={{ p: 5, mt: 4 }}>
        <Loader />
      </Paper>
    );

  return (
    <>
      <Paper sx={{ pb: 5, px: 5, mt: 4, pt: 2 }}>
        <Typography variant="h6">Subscriptions</Typography>
        {!currentSubscription || isSubscriptionCancelled ? (
          <Box component="div" sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              You are not subscribed to any plan.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => openCheckout(PADDLE_SUBSCRIPTION_PRICE_ID)}
              sx={{ mt: 2 }}
            >
              Subscribe
            </Button>
          </Box>
        ) : currentSubscription.scheduled_cancellation ? (
          <Box component="div" sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              Your subscription is scheduled to be cancelled{' '}
              {new Date(currentSubscription.scheduled_cancellation).toLocaleDateString()}
            </Typography>
          </Box>
        ) : (
          <Box component="div" sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              Your subscription is active and started at{' '}
              {new Date(currentSubscription.purchased_at).toLocaleDateString()}
            </Typography>
            <Button
              variant="outlined"
              color="error"
              sx={{ mt: 2 }}
              onClick={handleCancelSubscriptionModal}
            >
              Cancel Subscription
            </Button>
          </Box>
        )}
      </Paper>

      <CancelSubscriptionFeedbackForm
        open={isCancelSubscriptionModalOpen}
        onClose={() => setCancelSubscriptionModalOpen(false)}
        onSubmit={onSubmitCancelSubscriptionFeedback}
        isProcessing={isCancelSubscriptionProcessing}
      />

      {!currentUser?.organization_id &&
        (!students || isStudentsLoading ? (
          <Loader />
        ) : (
          <Paper sx={{ pb: 5, px: 5, mt: 4 }}>
            <Box component="div" sx={{ mt: 2 }}>
              <Typography variant="h6" sx={{ py: 2 }}>
                Purchased Courses
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Courses</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students.map(({ student, courses }) => (
                      <TableRow key={student.id}>
                        <TableCell>{student.full_name || 'N/A'}</TableCell>
                        <TableCell>{student.username}</TableCell>
                        <TableCell>
                          {courses.map((course) => (
                            <Chip key={course} label={course} sx={{ marginRight: 1 }} />
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        ))}
    </>
  );
}
