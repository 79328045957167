import React, { useContext, useMemo, useRef, useState } from 'react';
import { SceneExitEvent, ScenePreExitEvent } from './Scene';
import useGame from './useGame';
import { SceneStoreProvider } from './useGameObjectStore';
import waitForMs from './utils/waitForMs';
import { Constant, CourseData, LevelData, LevelGoal } from '../client';
import { FinishLevelEvent } from './LevelComplete';
import { useGenericModal } from '../context/GenericModalContext';
import usePyodide from '../hooks/usePyodide';
import { UNEXPECTED_ERRORS } from './utils/gameErrors';
import { useOutput } from '../context/OutputContext';
import { UserContext } from '../pages/App';
import { useCommandError } from '../hooks/useCommandError';
import { checkDataIsEqual } from './utils/commandHelper';
import { XMARK_LIST } from './utils/xmarkList';
import { useCode } from '../context/CodeContext';
import { useVirtualAssistantContext } from '../context/VirtualAssistantContext';
import { useQueryClient } from 'react-query';

export type GameLevelData = {
  score: number;
  maxScore: number;
  type?: string;
  action: string;
  position?: number[];
  variable?: string;
  data?: any;
}[];

export type InventoryData = {
  type: string;
  count: number;
}[];

export interface SceneManagerContextValue {
  currentScene: string;
  currentLevel: number;
  prevLevel: number;
  levelData: GameLevelData;
  inventory: InventoryData;
  isLevelCompleted: boolean;
  isQueueCompleted: boolean;
  setScene: (sceneId: string) => Promise<void>;
  setLevel: (level: number) => Promise<void>;
  resetScene: () => Promise<void>;
  setSceneState: (key: string, value: any) => void;
  getSceneState: (key: string) => any;
  setLevelData: (currentLevelData: GameLevelData) => Promise<void>;
  resetLevelData: (levelGoals: LevelGoal[]) => Promise<void>;
  setInventoryData: (inventory: InventoryData) => Promise<void>;
  setIsLevelCompleted: (value: boolean) => void;
  setIsQueueCompleted: (value: boolean) => void;
  checkCurrentLevelCompleted: () => void;
  checkVariableGoalsCompleted: () => Promise<boolean>;
  checkDataExpectationMet: () => Promise<boolean>;
  resetConstants: (constants: Constant[]) => Promise<void>;
}

export const SceneManagerContext = React.createContext<SceneManagerContextValue>(null!);

interface Props {
  defaultScene: string;
  children: React.ReactNode;
  levelData: LevelData;
  courseData: CourseData;
}

export const normalizeLevelGoalItem = (goal: LevelGoal) => {
  const { type, cost, action, variable, depends, expectedData } = goal;

  if (variable) {
    return {
      score: 0,
      maxScore: depends?.count || 0,
      type: depends?.type || '',
      action: action,
      position: goal?.position,
      variable
    };
  }

  if (expectedData) {
    return {
      score: 0,
      maxScore: 0,
      type: type,
      action: action,
      expectedData
    };
  }

  if (XMARK_LIST.includes(type!) && goal.data !== 'water') {
    return {
      score: 0,
      maxScore: goal.count,
      type: type || '',
      action: action,
      position: goal?.position,
      data: goal.data
    };
  }

  switch (type) {
    case 'xmarkLight':
    case 'xmarkGold':
      return {
        score: 0,
        maxScore: goal.count,
        type: cost?.type || type,
        action: action,
        position: goal?.position,
        data: goal.data
      };
    default:
      return {
        score: 0,
        maxScore: goal.count,
        type: type || '',
        action: action,
        position: goal?.position,
        data: goal.data
      };
  }
};

export const normalizeLevelGoalsData = (data: LevelGoal[]): GameLevelData => {
  return data.map((goal) => normalizeLevelGoalItem(goal));
};

export const normalizeInventoryItem = (goal: LevelGoal) => {
  const { type, cost } = goal;

  switch (type) {
    case 'xmarkLight':
    case 'xmarkGold':
      return {
        type: cost?.type || '',
        count: 0
      };
    default:
      return {
        type: type || '',
        count: 0
      };
  }
};

export const normalizeInventoryData = (data: LevelGoal[]): InventoryData => {
  return data
    .filter((goal) => goal.action === 'collect')
    .map((goal) => normalizeInventoryItem(goal));
};

export default function SceneManager({ defaultScene, levelData, courseData, children }: Props) {
  const { publish, findGameObjectByName } = useGame();
  const { runScript, errorMessage } = usePyodide();
  const { codeRef } = useCode();
  const { resetCommandsQueue, commandsInQueue } = useOutput();
  const { levelGoals, constants } = levelData;
  const [initialScene, initialLevel = 0] = defaultScene.split(':');
  const [currentScene, setScene] = useState(initialScene);
  const initialLevelData = normalizeLevelGoalsData(levelGoals);
  const [currentLevelData, setCurrentLevelData] = useState<GameLevelData>(initialLevelData);
  const [inventory, setInventory] = useState(normalizeInventoryData(levelGoals));
  const [isQueueCompleted, setIsQueueCompleted] = useState<boolean>(false);
  const currentUser = useContext(UserContext);
  const prevLevel = useRef(-1);
  const currentLevel = useRef(Number(initialLevel));
  const sceneStore = useRef(new Map<string, any>());
  const [isLevelCompleted, setIsLevelCompleted] = useState(false);
  const { setOpenModal: setOpenLevelCompleteModal } = useGenericModal('level-complete');
  const { setOpenModal: setOpenCourseCompleteModal } = useGenericModal('course-complete');
  const { triggerError } = useCommandError();
  const queryClient = useQueryClient();
  const { generateTipLevelAsync } = useVirtualAssistantContext();

  const api = useMemo<SceneManagerContextValue>(() => {
    (async () => {
      if (!isLevelCompleted && isQueueCompleted) {
        setIsQueueCompleted(false);
        const player = findGameObjectByName('player');
        const playerPosition = [player.transform.x, player.transform.y];
        const facingDirection = [player.transform.rotationX, player.transform.rotationY];
        await generateTipLevelAsync(levelData, playerPosition, facingDirection, errorMessage);
        queryClient.invalidateQueries('credits-level_assistant');
      }
    })();

    return {
      currentScene,
      prevLevel: prevLevel.current,
      currentLevel: currentLevel.current,
      levelData: currentLevelData,
      inventory,
      isLevelCompleted,
      isQueueCompleted,
      async setScene(nextScene) {
        // eslint-disable-next-line prefer-const
        let [targetScene, targetLevel = 0] = nextScene.split(':');
        targetLevel = Number(targetLevel);

        if (currentScene !== targetScene) {
          // switch scene
          if (currentScene !== '') {
            await publish<ScenePreExitEvent>('scene-pre-exit', currentScene);
            await publish<SceneExitEvent>('scene-exit', currentScene);
            // always go to empty scene first and then to the target scene
            // (this should help clearing cached react components)
            setScene('');
            await waitForMs(100);
          }
          prevLevel.current = -1;
          currentLevel.current = targetLevel;
          setScene(targetScene);
        } else if (currentLevel.current !== targetLevel) {
          // switch level
          api.setLevel(targetLevel);
        }
      },
      async setLevel(level) {
        if (level !== currentLevel.current) {
          prevLevel.current = currentLevel.current;
          currentLevel.current = level;
          await api.setLevelData([...initialLevelData]);
          await api.resetScene();
        }
      },
      async resetScene() {
        const prevScene = currentScene;
        const formerCurrentLevel = currentLevel.current;
        const formerPrevLevel = prevLevel.current;
        // switch to empty scene
        await api.setScene('');
        await api.resetLevelData(levelGoals);
        await api.setInventoryData(normalizeInventoryData(levelGoals));
        await api.resetConstants(constants || []);
        resetCommandsQueue();
        await waitForMs(100);
        // restore prev scene + level
        prevLevel.current = formerPrevLevel;
        currentLevel.current = formerCurrentLevel;
        setScene(prevScene);
      },
      setSceneState(key, value) {
        sceneStore.current.set(`${currentScene}.${key}`, value);
      },
      getSceneState(key) {
        return sceneStore.current.get(`${currentScene}.${key}`);
      },
      async setLevelData(data) {
        setCurrentLevelData(data);
      },
      async resetLevelData(data) {
        setCurrentLevelData(normalizeLevelGoalsData(data));
      },
      async setInventoryData(data) {
        setInventory(data);
      },
      async resetConstants(constants) {
        try {
          const code = constants
            .map((constant) => `globals()["${constant.name}"] = ${JSON.stringify(constant.value)}`)
            .join('\n');
          await runScript(code, false);
        } catch (error) {
          console.error('Error resetting constants:', error);
        }
      },
      setIsLevelCompleted(value) {
        setIsLevelCompleted(value);
      },
      setIsQueueCompleted(value) {
        setIsQueueCompleted(value);
      },
      async checkVariableGoalsCompleted() {
        let areVariableGoalsCompleted = true;
        let failingVariableGoal: string | undefined;
        const assignVariableGoals = levelGoals.filter(
          (goal) => goal.action === 'assign' && goal.variable
        );

        levelGoals.forEach((levelGoal) => {
          assignVariableGoals.forEach(async (g) => {
            // Fetch the value from the worker
            let variable = '';
            try {
              const result = await runScript(`globals().get("${g.variable}")`, false, false, true);
              variable = result.results;
            } catch (error) {
              console.error(`Error fetching variable ${g.variable}:`, error);
            }
            if (!variable && Number(variable) !== 0) return;

            let valueToCompare: string | number = g.depends?.count as number;
            if (typeof variable === 'string' && typeof g.data === 'string') {
              variable = variable.toLowerCase();
              valueToCompare = g.depends?.type as string;
            }
            if (levelGoal.type === g.depends?.type && variable !== valueToCompare) {
              areVariableGoalsCompleted = false;
              failingVariableGoal = g.variable;
              return false;
            }
          });
        });

        if (!areVariableGoalsCompleted) {
          triggerError(UNEXPECTED_ERRORS.assign.variable.isNotEqual(failingVariableGoal));
          return false;
        }
        return true;
      },
      async checkDataExpectationMet() {
        try {
          const mainExecutionResult = await runScript(codeRef.current, false, false, true);

          const updatedVariables = mainExecutionResult?.updatedConstants;

          if (!updatedVariables) {
            console.error('Updated variables are undefined after script execution.');
            return false;
          }

          let areDataExpectationsMet = true;
          let failingDataExpectation: string | undefined;

          const dataExpectations = levelGoals.filter(
            (goal) => goal.action === 'compare' && goal.variable
          );

          for (const goal of dataExpectations) {
            const variableName = goal.variable;

            if (!variableName) {
              console.warn('Goal variable is undefined.');
              continue;
            }

            // Retrieve the updated variable value
            const variable = updatedVariables[variableName];

            if (variable === undefined) {
              console.log(`Variable ${variableName} is undefined in updatedVariables.`);
              continue;
            }

            const expectedData = goal.expectedData;
            const actualData = { ...variable };

            // Check if the actual data matches the expected data
            if (!checkDataIsEqual(actualData, expectedData)) {
              areDataExpectationsMet = false;
              failingDataExpectation = variableName;
              // console.log(`Data mismatch found for variable: ${variableName}`);
              break;
            }
          }

          if (!areDataExpectationsMet) {
            triggerError(UNEXPECTED_ERRORS.compare.variable.isNotEqual(failingDataExpectation));
            return false;
          }

          return true;
        } catch (error) {
          console.error('Error during checkDataExpectationMet:', error);
          return false;
        }
      },
      async checkCurrentLevelCompleted() {
        await api.checkVariableGoalsCompleted();
        // Uncomment the following line to debug if the level is not completed
        // console.log('Current level data:', currentLevelData);
        const isCompleted = currentLevelData.every((goal) => goal.score === goal.maxScore);

        if (isCompleted) {
          const isDataExpectationMet = await api.checkDataExpectationMet();
          if (!isDataExpectationMet) return;
          api.setIsLevelCompleted(true);
          resetCommandsQueue();

          waitForMs(800).then(() => {
            publish<FinishLevelEvent>('finish-level', true);
          });
          waitForMs(800).then(() => {
            // Check if the course is completed before opening the modal
            const courseCompleted = courseData.chapters.every((chapter) =>
              chapter.level_data.every((level) => level.completed || level.slug === levelData.slug)
            );
            if (!courseCompleted) {
              setOpenLevelCompleteModal(true);
            } else {
              setOpenCourseCompleteModal(true);
            }
          });
        }
      }
    };
  }, [
    currentScene,
    currentLevel,
    publish,
    currentLevelData,
    inventory,
    isLevelCompleted,
    commandsInQueue,
    runScript,
    isQueueCompleted
  ]);

  return (
    <SceneManagerContext.Provider value={api}>
      <SceneStoreProvider>{children}</SceneStoreProvider>
    </SceneManagerContext.Provider>
  );
}
