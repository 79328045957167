import { useQuery } from 'react-query';
import { PaymentService } from '../client';

export const useCurrentSubscription = () => {
  const { data, isLoading } = useQuery(
    'current-subscription',
    PaymentService.currentSubscriptionApiPaymentCurrentSubscriptionGet,
    {
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false
    }
  );

  return { data, isLoading };
};
