import React, { forwardRef } from 'react';
import { Avatar, Box, Button, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import TipBox from './TipBox';
import { useTranslation } from 'react-i18next';
import ContentMarkdown from '../GameUI/ContentMarkdown';
import { useVirtualAssistantContext } from '../../context/VirtualAssistantContext';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useLocaleContext } from '../../context/LocaleContext';

const CodeAdderAssistant = forwardRef<HTMLDivElement, { sx: SxProps; onClick: (resultedCode: string) => void }>(
  ({ sx, onClick }, ref) => {
    const { t } = useTranslation();
    const { currentUser } = useCurrentUser();
    const { tip, isActivationsExceeded } = useVirtualAssistantContext();
    const { getLocaleLink } = useLocaleContext();

    return (
      <TipBox ref={ref} sx={sx}>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Stack>
            <Avatar
              sx={{
                width: 60,
                height: 60,
                objectFit: 'cover',
                mt: -1.5,
              }}
              alt="Avatar"
              src="/assets/sprites/geek.png"
            />
          </Stack>
          <Stack direction="column" justifyContent="center" sx={{ maxWidth: 300 }}>
            <ContentMarkdown content={tip?.text || ''} sx={{minHeight: "fit-content", fontSize: '14px !important', '& p': {fontSize: '16px'}}}/>
            {tip?.code_to_add && (
              <Box component="div" display="flex" flexDirection='column' gap={1.5} sx={{mt: "2px"}}>
                <SyntaxHighlighter style={monokaiSublime} PreTag="div" language="python" customStyle={{fontSize: '14px'}}>
                  {tip?.code_to_add?.trim() || ''}
                </SyntaxHighlighter>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{width: 'fit-content', mx: 'auto'}}
                  onClick={() => onClick(tip?.resulted_code || '')}
                >
                  {t('codeEditor.applyButton')}
                </Button>
              </Box>
            )}
            {isActivationsExceeded && currentUser?.student_id && (
              <Box component="div" display="flex" sx={{margin: '0 auto'}}>
                <Button
                  variant="contained"
                  href={getLocaleLink("/purchase")}
                >
                  Upgrade
                </Button>
              </Box>
            )}
            {isActivationsExceeded && !currentUser && (
              <Box component="div" display="flex" sx={{margin: '0 auto'}}>
                <Button
                  variant="contained"
                  href={getLocaleLink("/signup")}
                >
                  Sign Up
                </Button>
              </Box>
            )}
          </Stack>
        </Box>
      </TipBox>
    );
  }
);

export default CodeAdderAssistant;
