import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { cancelSubscriptionFeedbackSchema } from '../components/Feedback/FeedbackSchema';

export const useCancelSubscriptionFeedbackForm = () => {
  const formMethods = useForm({
    resolver: yupResolver(cancelSubscriptionFeedbackSchema),
    mode: 'onChange',
    defaultValues: {
      cancel_subscription_reason: '',
      feedback: '',
    },
  });
  return formMethods;
};