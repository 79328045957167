import { useQueryClient } from 'react-query';
import useLevels from '../components/useLevels';
import { useGenericModal } from '../context/GenericModalContext';
import { useOutput } from '../context/OutputContext';
import { useVirtualAssistantContext } from '../context/VirtualAssistantContext';
import useGame from '../@core/useGame';

export const useCommandError = () => {
  const { resetCommandsQueue } = useOutput();
  const { findGameObjectByName } = useGame();
  const { levelData } = useLevels();
  const queryClient = useQueryClient();

  const {
    generateTipLevelAsync,
  } = useVirtualAssistantContext();
  const { setContent: setError, setOpenModal: setOpenErrorModal } = useGenericModal('error');

  const triggerError = async (error: string) => {
    resetCommandsQueue();
    setError(error || 'Unexpected game error');
    setOpenErrorModal(true);
    if (!levelData) return;
    const player = findGameObjectByName('player');
    const playerPosition = player ? [player.transform.x, player.transform.y] : [0, 0];
    const facingDirection = player ? [player.transform.rotationX, player.transform.rotationY] : [0, -1];
    await generateTipLevelAsync(levelData, playerPosition, facingDirection, error || 'Unexpected game error');
    queryClient.invalidateQueries('credits-level_assistant');
  };

  return { triggerError };
};
