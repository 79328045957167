import useGameEvent from './useGameEvent';
import { PubSubEvent } from './utils/createPubSub';
import useSceneManager from './useSceneManager';
import useGame from './useGame';
import { useVirtualAssistantContext } from '../context/VirtualAssistantContext';

export type FinishLevelEvent = PubSubEvent<'finish-level', boolean>;

const LevelComplete = () => {
  const { checkCurrentLevelCompleted } = useSceneManager();
  const { findGameObjectByName } = useGame();
  const { setPlayerPosition } = useVirtualAssistantContext();

  useGameEvent('did-collect', async () => {
    checkCurrentLevelCompleted();
    const player = findGameObjectByName('player');
    const playerPosition = player ? [player.transform.x, player.transform.y] : [0, 0];
    setPlayerPosition(playerPosition);
  });

  return null;
};

export default LevelComplete;
