import React, { useEffect } from 'react';
import { Typography, CircularProgress, Box, Tooltip } from '@mui/material';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import QuestionCoinAnimationView from '../QuestionCoinAnimationView';
import CreditHeartAnimationView from '../CreditHeartAnimationView';
import { useQueryClient } from 'react-query';

const MAX_COUNTER = {
  unauthorized: 10,
  authorized: 30
};

const MAX_COUNTER_ASSISTANT = {
  unauthorized: 5,
  authorized: 20
};

interface CreditCounterProps {
  credits?: {
    counter: number;
    execution_type: string;
  };
  loading: boolean;
  isCodeRunning: boolean;
}

const CreditCounter: React.FC<CreditCounterProps> = ({ credits, loading, isCodeRunning }) => {
  const { currentUser } = useCurrentUser();
  const studentId = currentUser?.student_id;
  const paidUser = currentUser?.user_details?.paid;
  let isUnlimited = paidUser;
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries('credits-challenge');
    queryClient.invalidateQueries('credits-challenge_assistant');
  }, [studentId]);

  if (loading) {
    return <CircularProgress size={24} />;
  }

  let limitedText = '';
  let tooltipTitle = '';
  let maxCounter;

  switch (credits?.execution_type) {
    case 'level_assistant':
    case 'challenge_assistant':
      maxCounter = studentId
        ? MAX_COUNTER_ASSISTANT.authorized
        : MAX_COUNTER_ASSISTANT.unauthorized;
      limitedText = studentId
        ? 'Subscribe to get unlimited activations'
        : 'Log in to get more actiovations';
      tooltipTitle = !paidUser ? limitedText : 'Unlimited activations available';
      break;
    case 'level':
      isUnlimited = paidUser || !!studentId;
      maxCounter = !studentId && MAX_COUNTER.unauthorized;
      limitedText = studentId ? 'Unlimited attempts available' : 'Log in to get unlimited attempts';
      tooltipTitle = !isUnlimited ? limitedText : 'Unlimited attempts available';
      break;
    case 'challenge':
      maxCounter = studentId ? MAX_COUNTER.authorized : MAX_COUNTER.unauthorized;
      limitedText = studentId
        ? 'Subscribe to get unlimited attempts'
        : 'Log in to get more attempts';
      tooltipTitle = !paidUser ? limitedText : 'Unlimited attempts available';
      break;
  }

  const getAnimationView = (play: boolean) => {
    switch (credits?.execution_type) {
      case 'level_assistant':
      case 'challenge_assistant':
        return <QuestionCoinAnimationView play={play} />;
      case 'level':
      case 'challenge':
        return <CreditHeartAnimationView play={play} />;
      default:
        return null;
    }
  };

  return (
    <Tooltip title={tooltipTitle}>
      <Box component="div" display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
        {getAnimationView(isCodeRunning)}
        {isUnlimited ? (
          <AllInclusiveIcon />
        ) : (
          <Typography variant="h6" sx={{ fontSize: '16px' }}>
            {credits?.counter}/{maxCounter}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};

export default CreditCounter;
