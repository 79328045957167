import GameObject, { GameObjectProps } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useSceneManager from '../@core/useSceneManager';
import useGame from '../@core/useGame';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import { DidCollectEvent } from '../@core/Collectable';
import waitForMs from '../@core/utils/waitForMs';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import { useRef } from 'react';
import {
  Data,
  checkDataIsEqual,
  convertToPrimitive,
  parseJson
} from '../@core/utils/commandHelper';
import { useCommandError } from '../hooks/useCommandError';
import { XMARK_LIST } from '../@core/utils/xmarkList';
import { Asking } from '../@core/utils/movementHelpers';

function QuestionScript() {
  const xmarkState = useRef(false);
  const { getRef } = useGameObject();
  const { publish, findGameObjectByName } = useGame();

  const { levelData, setLevelData } = useSceneManager();
  const { apiLevelData } = useApiLevelData();
  const { triggerError } = useCommandError();

  useGameObjectEvent<InteractionEvent>('interaction', async ({ obj, step }) => {
    const askStep = step as Asking;

    const { questionMessage } = askStep;
    if (!questionMessage) return;

    if (!apiLevelData) return;

    const { levelGoals } = apiLevelData;
    const { items } = apiLevelData;

    const askItems = items?.filter((item) => item.action === 'question') || [];
    const positionAskable = askItems.find(
      (item) => item.position[0] === obj.transform.x && item.position[1] === obj.transform.y
    );

    const levelAskables = levelGoals?.filter(
      (goal) =>
        goal.action === 'question' &&
        goal.position?.[0] === obj.transform.x &&
        goal.position?.[1] === obj.transform.y
    );

    const levelAskable =
    levelAskables.length > 1
        ? levelAskables.find((goal) => checkDataIsEqual(goal.data, questionMessage))
        : levelAskables[0];

    if (!positionAskable) {
      triggerError(UNEXPECTED_ERRORS.question.position);
      return false;
    }

    if (!levelAskable) {
      triggerError(UNEXPECTED_ERRORS.question.wrongMessage);
      return false;
    }

    if (!checkDataIsEqual(levelAskable.data, questionMessage)) {
      triggerError(UNEXPECTED_ERRORS.question.data(levelAskable?.data));
      return false;
    }

    const playerObject = findGameObjectByName('player');
    const playerMoveable = playerObject?.getComponent('Moveable');
    await playerMoveable.ask({ questionMessage: convertToPrimitive(questionMessage) });

    const newLevelData = levelData.map((item) => {
      if (
        XMARK_LIST.includes(item.type || '') &&
        item.position?.[0] === obj.transform.x &&
        item.position?.[1] === obj.transform.y &&
        item.action === 'question' &&
        checkDataIsEqual(parseJson(item.data), questionMessage)
      ) {
        return { ...item, score: (item.score += 1) };
      }
      return item;
    });

    setLevelData(newLevelData);

    publish<DidCollectEvent>('did-collect', newLevelData);

    xmarkState.current = !xmarkState.current;

    if (xmarkState.current) {
      getRef().setDisabled(true);
    }
    return waitForMs(400);
  });

  return null;
}

interface ItemQuestionProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemQuestion({ props, state, spriteData }: ItemQuestionProps) {
  const name = `${state}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Interactable type="question" />
      <QuestionScript />
    </GameObject>
  );
}
