import React, { forwardRef } from 'react';
import { Box, IconButton } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import { IconX } from '@tabler/icons-react';
import { useVirtualAssistantContext } from '../../context/VirtualAssistantContext';

const BorderImageBox = styled(Box)(({ borderImage }: { borderImage: string }) => ({
  borderStyle: 'solid',
  borderWidth: '16px',
  borderImage: `url(${borderImage}) 24 repeat`,
  color: 'black',
  position: 'relative'
}));

const TipBox = forwardRef<HTMLDivElement, { children: React.ReactNode; sx: SxProps }>(
  ({ children, sx }, ref) => {
    const { setTipVisible } = useVirtualAssistantContext();

    return (
      <BorderImageBox ref={ref} borderImage="/assets/sprites/tip-square-arrow-top.png" sx={sx}>
        <Box
          component="div"
          sx={{
            backgroundColor: '#E8CFA6',
          }}
        >
          {children}
        </Box>
        <IconButton
          sx={{
            position: 'absolute',
            top: -12,
            right: -12,
            zIndex: 1000,
            '&:hover': { backgroundColor: 'transparent' }
          }}
          size="small"
          onClick={() => setTipVisible(false)}
        >
          <IconX style={{ width: 16, color: 'black' }} />
        </IconButton>
      </BorderImageBox>
    );
  }
);

export default TipBox;
