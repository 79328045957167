import { SpriteProps } from './@core/Sprite';

export type SpriteData = { [index: string]: SpriteProps };

const spriteData: SpriteData = {
  ui: {
    src: '/assets/sprites/ui.png',
    sheet: {
      'self-select': [
        [4, 0],
        [5, 0]
      ],
      select: [[4, 0]],
      dot: [[1, 0]],
      solid: [[0, 1]]
    }
  },
  // TODO: let’s keep it for now
  // player: {
  //   src: '/assets/sprites/player.png',
  //   frameWidth: 16,
  //   frameHeight: 16,
  //   frameTime: 300,
  //   sheet: {
  //     default: [[1, 10]],
  //     // walk: [
  //     //     [0, 3],
  //     // ],
  //     moveUp: [[1, 7]],
  //     moveDown: [[1, 10]],
  //     moveLeft: [[1, 4]],
  //     moveRight: [[1, 1]],
  //     action: [
  //       [0, 1],
  //       [2, 1]
  //     ]
  //   }
  // },
  Rabbit: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 20]],
      moveUp: [[1, 20]],
      moveDown: [[0, 20]],
      moveLeft: [[3, 20]],
      moveRight: [[2, 20]]
    }
  },
  Goblin: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 9]],
      moveUp: [[1, 9]],
      moveDown: [[0, 9]],
      moveLeft: [[3, 9]],
      moveRight: [[2, 9]]
    }
  },
  Viking: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 6]],
      moveUp: [[1, 6]],
      moveDown: [[0, 6]],
      moveLeft: [[3, 6]],
      moveRight: [[2, 6]]
    }
  },
  Detective: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 4]],
      moveUp: [[1, 4]],
      moveDown: [[0, 4]],
      moveLeft: [[3, 4]],
      moveRight: [[2, 4]]
    }
  },
  Knight: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 8]],
      moveUp: [[1, 8]],
      moveDown: [[0, 8]],
      moveLeft: [[3, 8]],
      moveRight: [[2, 8]]
    }
  },
  Punk: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 11]],
      moveUp: [[1, 11]],
      moveDown: [[0, 11]],
      moveLeft: [[3, 11]],
      moveRight: [[2, 11]]
    }
  },
  Glasses: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 15]],
      moveUp: [[1, 15]],
      moveDown: [[0, 15]],
      moveLeft: [[3, 15]],
      moveRight: [[2, 15]]
    }
  },
  Boy: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 17]],
      moveUp: [[1, 17]],
      moveDown: [[0, 17]],
      moveLeft: [[3, 17]],
      moveRight: [[2, 17]]
    }
  },
  Boy2: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 2]],
      moveUp: [[1, 2]],
      moveDown: [[0, 2]],
      moveLeft: [[3, 2]],
      moveRight: [[2, 2]]
    }
  },
  Girl: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 16]],
      moveUp: [[1, 16]],
      moveDown: [[0, 16]],
      moveLeft: [[3, 16]],
      moveRight: [[2, 16]]
    }
  },
  Girl2: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 3]],
      moveUp: [[1, 3]],
      moveDown: [[0, 3]],
      moveLeft: [[3, 3]],
      moveRight: [[2, 3]]
    }
  },
  Kid1: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 1]],
      moveUp: [[1, 1]],
      moveDown: [[0, 1]],
      moveLeft: [[3, 1]],
      moveRight: [[2, 1]]
    }
  },
  Kid2: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 0]],
      moveUp: [[1, 0]],
      moveDown: [[0, 0]],
      moveLeft: [[3, 0]],
      moveRight: [[2, 0]]
    }
  },
  Lady: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 14]],
      moveUp: [[1, 14]],
      moveDown: [[0, 14]],
      moveLeft: [[3, 14]],
      moveRight: [[2, 14]]
    }
  },
  Lady2: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 12]],
      moveUp: [[1, 12]],
      moveDown: [[0, 12]],
      moveLeft: [[3, 12]],
      moveRight: [[2, 12]]
    }
  },
  Wizard1: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 5]],
      moveUp: [[1, 5]],
      moveDown: [[0, 5]],
      moveLeft: [[3, 5]],
      moveRight: [[2, 5]]
    }
  },
  Wizard2: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 5]],
      moveUp: [[1, 5]],
      moveDown: [[0, 5]],
      moveLeft: [[3, 5]],
      moveRight: [[2, 5]]
    }
  },
  Luimberjack: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 19]],
      moveUp: [[1, 19]],
      moveDown: [[0, 19]],
      moveLeft: [[3, 19]],
      moveRight: [[2, 19]]
    }
  },
  FarmerBoy: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 10]],
      moveUp: [[1, 10]],
      moveDown: [[0, 10]],
      moveLeft: [[3, 10]],
      moveRight: [[2, 10]]
    }
  },
  OldMan: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 18]],
      moveUp: [[1, 18]],
      moveDown: [[0, 18]],
      moveLeft: [[3, 18]],
      moveRight: [[2, 18]]
    }
  },
  OldMan2: {
    src: '/assets/sprites/characters.png',
    frameWidth: 16,
    frameHeight: 16,
    frameTime: 300,
    sheet: {
      default: [[0, 13]],
      moveUp: [[1, 13]],
      moveDown: [[0, 13]],
      moveLeft: [[3, 13]],
      moveRight: [[2, 13]]
    }
  },
  objects: {
    src: '/assets/sprites/objects.png',
    frameWidth: 20,
    frameHeight: 20,
    sheet: {
      floor: [[0, 0]],
      wall: [[1, 0]],
      'workstation-1': [[0, 1]],
      'workstation-2': [[1, 1]],
      'coffee-machine': [[2, 1]],
      'coffee-machine-empty': [[3, 1]],
      pizza: [[4, 1]],
      plant: [[0, 2]]
    }
  },
  footstep: {
    src: '/assets/sprites/footstep.png',
    sheet: {
      default: [
        [0, 0],
        [2, 0]
      ]
    },
    opacity: 0.75,
    frameTime: 150
  },
  items: {
    src: '/assets/tileset_png/items_tileset.png',
    frameWidth: 16,
    frameHeight: 16,
    sheet: {
      log: [[3, 12]],
      berry: [[3, 2]],
      star: [[8, 12]],
      rock: [[4, 2]],
      xmark: [[14, 8]],
      xmarkLight: [[14, 9]],
      bridgeV: [[5, 2]],
      bridgeH: [[6, 2]],
      canteen: [[2, 13]],
      plank: [[2, 10]],
      brick: [[3, 10]],
      grain: [[1, 6]],
      chestOpened: [[14, 3]],
      chestClosed: [[14, 9]],
      gateOpened: [
        [7.5, 1],
        [7.5, 0],
        [6.5, 2],
        [6.5, 1],
        [6.5, 0]
      ],
      gateClosed: [
        [6.5, 0],
        [6.5, 1],
        [6.5, 2],
        [7.5, 0],
        [7.5, 1]
      ],
      trayEmpty: [
        [7.5, 4],
        [7.5, 5],
        [7.5, 6]
      ],
      trayFill: [
        [7.5, 6],
        [7.5, 4],
        [7.5, 5]
      ],
      trayFoodEmpty: [
        [7.5, 4],
        [7.5, 2],
        [7.5, 3]
      ],
      trayFoodFill: [
        [7.5, 4],
        [7.5, 2],
        [7.5, 3]
      ],
      pumpkin: [[1, 5]],
      eggplant: [[1, 9]],
      tomato: [[1, 10]],
      singleDoorClosed: [
        [14, 0],
        [14, 1],
        [14, 2],
        [14, 3],
        [14, 4]
      ],
      singleDoorOpened: [
        [14, 4],
        [14, 3],
        [14, 2],
        [14, 1],
        [14, 0]
      ],
      blueegg: [[7, 3]],
      redegg: [[5, 3]],
      greenegg: [[6, 3]],
      lightegg: [[3, 3]],
      darkegg: [[4, 3]],
      milk: [[4, 12]],
      milk2: [[5, 12]],
      milk3: [[6, 12]],
      milk4: [[7, 12]],
      halfmilk: [[4, 13]],
      halfmilk2: [[5, 13]],
      halfmilk3: [[6, 13]],
      halfmilk4: [[7, 13]],
      cream: [[4, 4]],
      cream2: [[5, 4]],
      cream3: [[6, 4]],
      cream4: [[7, 4]],
      halfcream: [[4, 5]],
      halfcream2: [[5, 5]],
      halfcream3: [[6, 5]],
      halfcream4: [[7, 5]],
      brown: [[4, 10]],
      brown2: [[5, 10]],
      brown3: [[6, 10]],
      brown4: [[7, 10]],
      halfbrown: [[4, 11]],
      halfbrown2: [[5, 11]],
      halfbrown3: [[6, 11]],
      halfbrown4: [[7, 11]],
      purple: [[4, 8]],
      purple2: [[5, 8]],
      purple3: [[6, 8]],
      purple4: [[7, 8]],
      halfpurple: [[4, 9]],
      halfpurple2: [[5, 9]],
      halfpurple3: [[6, 9]],
      halfpurple4: [[7, 9]],
      red: [[4, 6]],
      red2: [[5, 6]],
      red3: [[6, 6]],
      red4: [[7, 6]],
      halfred: [[4, 7]],
      halfred2: [[5, 7]],
      halfred3: [[6, 7]],
      halfred4: [[7, 7]],
      bag: [[2, 2]],
      grape: [[2, 3]],
      strawberry: [[2, 4]],
      lettuce: [[1, 7]],
      radish: [[1, 2]],
      cucumber: [[1, 0]],
      carrot: [[1, 12]],
      jar1: [[4, 14]],
      jar2: [[6, 14]],
      emptymix1: [[19, 14]],
      emptymix2: [[21, 14]],
      whitemix: [[19, 12]],
      whitemix1: [[20, 12]],
      whitemix2: [[21, 12]],
      whitemix3: [[22, 12]],
      halfwhitemix: [[19, 13]],
      halfwhitemix1: [[20, 13]],
      halfwhitemix2: [[21, 13]],
      halfwhitemix3: [[22, 13]],
      brownmix: [[19, 10]],
      brownmix1: [[20, 10]],
      brownmix2: [[21, 10]],
      brownmix3: [[22, 10]],
      halfbrownmix: [[19, 11]],
      halfbrownmix1: [[20, 11]],
      halfbrownmix2: [[21, 11]],
      halfbrownmix3: [[22, 11]],
      purplemix: [[19, 8]],
      purplemix1: [[20, 8]],
      purplemix2: [[21, 8]],
      purplemix3: [[22, 8]],
      halfpurplemix: [[19, 9]],
      halfpurplemix1: [[20, 9]],
      halfpurplemix2: [[21, 9]],
      halfpurplemix3: [[22, 9]],
      redmix: [[19, 6]],
      redmix1: [[20, 6]],
      redmix2: [[21, 6]],
      redmix3: [[22, 6]],
      halfredmix: [[19, 7]],
      halfredmix1: [[20, 7]],
      halfredmix2: [[21, 7]],
      halfredmix3: [[22, 7]],
      creammix: [[19, 4]],
      creammix1: [[20, 4]],
      creammix2: [[21, 4]],
      creammix3: [[22, 4]],
      halfcreammix: [[19, 5]],
      halfcreammix1: [[20, 5]],
      halfcreammix2: [[21, 5]],
      halfcreammix3: [[22, 5]],
      apple: [[2, 8]],
      pear: [[2, 6]],
      orange: [[2, 7]],
      stone: [[2, 9]],
      starDraw: [[13, 10]],
      peach: [[2, 5]],
      weed: [[3, 13]],
      blueberry: [[3, 5]],
      blackberry: [[3, 4]],
      parsnip: [[1, 4]],
      corn: [[1, 13]],
      branch: [[2, 12]],
      boulder: [[3, 9]],
      basket: [[3, 8]],
      xmarkGold: [[14, 10]],
      purplebottle: [[10, 10]],
      greenbottle: [[12, 10]],
      yellowbottle: [[11, 10]],
      bluebottle: [[13, 10]],
      oilbottle: [[8, 10]],
      emptybottle: [[9, 10]],
      onion: [[1, 14]],
      garlic: [[7, 2]],
      meat: [[3, 7]],
      potato: [[3, 6]],
      logs: [[5, 14]],
      ovenDoorClosed: [
        [13, 2],
        [13, 3],
        [13, 4],
        [13, 5],
        [13, 6]
      ],
      ovenDoorOpened: [
        [13, 6],
        [13, 5],
        [13, 4],
        [13, 3],
        [13, 2]
      ],
    }
  },
  barn: {
    src: '/assets/tileset_png/barn_tileset.png',
    frameWidth: 16,
    frameHeight: 16,
    sheet: {
      grainstack: [[0, 2]]
    }
  },
  path: {
    src: '/assets/tileset_png/path_tileset.png',
    frameWidth: 16,
    frameHeight: 16,
    sheet: {
      wood: [[0, 3]],
      stone: [[4, 2]]
    }
  },
  chickenHouseBig: {
    src: '/assets/tileset_png/chicken_house_tileset.png',
    frameWidth: 48,
    frameHeight: 48,
    sheet: {
      red: [[-1, 1]],
      orange: [[0, 1]],
      yellow: [[2, 1]],
      green: [[-1, 0]],
      blue: [[5, 0]],
      purple: [[0, 0]]
    }
  },
  chickenHouseSmall: {
    src: '/assets/tileset_png/chicken_house_tileset.png',
    frameWidth: 32,
    frameHeight: 48,
    sheet: {
      red: [[4, 1]],
      orange: [[6, 1]],
      yellow: [[7, 1]],
      green: [[4, 0]],
      blue: [[5, 0]],
      purple: [[6, 0]]
    }
  }
};

export default spriteData;
