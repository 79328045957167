import { useMutation } from 'react-query';
import { ApiError, ChallengeService, ChallengeStatusUpdate } from '../client';
import { useCurrentUser } from './useCurrentUser';
import { useGenericModal } from '../context/GenericModalContext';

export default function useChallengeCompletion() {
  const { currentUser } = useCurrentUser();
  const { setOpenModal: setOpenSignInModal } = useGenericModal('sign-up-credits-modal');
  const { setOpenModal: setOpenUpgradePlanModal } = useGenericModal('upgrade-plan-modal');

  const updateChallengeCompletion = useMutation(
    (updatedChallengeStatus: ChallengeStatusUpdate) => {
      return ChallengeService.upsertChallengeCompletionApiChallengeUpdatePost(
        updatedChallengeStatus
      );
    },
    {
      onError: (error: ApiError) => {
        if (error.status === 403) {
          if (currentUser) {
            setOpenUpgradePlanModal(true);
          } else {
            setOpenSignInModal(true);
          }
        }
      }
    }
  );
  return { updateChallengeCompletion };
}
