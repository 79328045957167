import { useState, useRef, useLayoutEffect } from 'react';
import { AskingEvent } from './Moveable';
import useGameObjectEvent from './useGameObjectEvent';
import waitForMs from './utils/waitForMs';
import { Primitive } from './utils/commandHelper';
import useGame from './useGame';
import useGameObject from './useGameObject';
import {
  calculatePosition,
  GameBackdrop,
  InGamePopUp,
  MessageBox
} from '../components/GameUI/InGamePopUp';
import { Html } from '@react-three/drei';
import { Box } from '@mui/material';
import PrimaryButton from '../components/GameUI/PrimaryButton';
import usePyodide from '../hooks/usePyodide';
import { useCode } from '../context/CodeContext';

interface QuestionPopUpProps {
  mapBounds: { width: number; height: number };
}

export default function QuestionPopUp({ mapBounds }: QuestionPopUpProps) {
  const [questionMessage, setQuestionMessage] = useState<Primitive | null>(null);
  const questionPopUpRef = useRef<HTMLDivElement>(null);
  const { findGameObjectByName } = useGame();
  const { getComponent } = useGameObject();
  const { runScript, continueExecution, setIsCodeRunning, postMessageToWorker } = usePyodide();
  const { code } = useCode();
  const moveableRef = getComponent('Moveable');

  useGameObjectEvent<AskingEvent>('asking', ({ questionMessage, isAsking }) => {
    if (!questionMessage) return;
    setIsCodeRunning(false);

    waitForMs(0).then(() => {
      setQuestionMessage(questionMessage as string);
    });
  });

  const handleAnswer = async (answer: boolean) => {
    setIsCodeRunning(false);
    setQuestionMessage(null);
    postMessageToWorker({ type: 'question', answer });
    waitForMs(0).then(async () => {
      moveableRef.setIsAsking(false);
      await continueExecution(code);
    });
  };

  useLayoutEffect(() => {
    if (questionMessage) {
      calculatePosition(mapBounds, questionPopUpRef, findGameObjectByName('player'));
    }
  }, [questionMessage]);

  return (
    <>
      {questionMessage && (
        <Html style={{ zIndex: '1300', cursor: 'default' }}>
          <GameBackdrop open={true} />
          <InGamePopUp popUpRef={questionPopUpRef}>
            <Box
              component="div"
              sx={{
                px: 2,
                pt: 2,
                pb: 1,
                display: 'flex',
                height: '100%',
                width: '100%',
                flexDirection: 'column'
              }}
            >
              <MessageBox>
                <Box component="div" className="typewriter">
                  {questionMessage}
                </Box>
              </MessageBox>
              <Box
                component="div"
                sx={{ mt: 2, display: 'flex', flexDirection: 'row', gap: '12px' }}
              >
                <PrimaryButton onClick={() => handleAnswer(true)} size="small">
                  Yes
                </PrimaryButton>
                <PrimaryButton onClick={() => handleAnswer(false)} size="small">
                  No
                </PrimaryButton>
              </Box>
            </Box>
          </InGamePopUp>
        </Html>
      )}
    </>
  );
}
