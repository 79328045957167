// import { css } from '@emotion/core';
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { GameObjectLayer, GameObjectRef } from './GameObject';
import { SceneExitEvent } from './Scene';
import createPubSub, { PubSub } from './utils/createPubSub';
import { Inventory } from './Inventory';
import { LevelData } from '../client';
import { Box, Button, Typography } from '@mui/material';
import { useAppContext } from '../context/AppContext';
import { UserContext } from '../pages/App';
import ChooseStudentOverlay from './ChooseStudentOverlay';

export type GameObjectRegistry<T = GameObjectRef> = Map<symbol | string, T>;

export interface GameObjectRegistryUtils {
  registerGameObject: (identifier: symbol, ref: GameObjectRef) => void;
  unregisterGameObject: (identifier: symbol, ref: GameObjectRef) => void;
  findGameObjectById: (id: symbol) => GameObjectRef;
  findGameObjectByName: (name: string) => GameObjectRef;
  findGameObjectsByXY: (x: number, y: number) => GameObjectRef[];
  findGameObjectsByLayer: (layer: GameObjectLayer) => GameObjectRef[];
  // findGameObjects: (props: Partial<GameObjectProps>) => GameObjectRef[];
  findCompoundObjectByXY: (x: number, y: number) => GameObjectRef | undefined;
}
export interface GameLevelData {
  score: number;
  maxScore: number;
}
export interface GameContextValue extends GameObjectRegistryUtils, PubSub {
  settings: {
    movementDuration: number;
    cameraZoom: number;
  };
  paused: boolean;
  setPaused: Dispatch<SetStateAction<boolean>>;
  mapSize: [number, number];
  setMapSize: Dispatch<SetStateAction<[number, number]>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setGameState: (key: string | symbol, value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getGameState: (key: string | symbol) => any;
}

export const GameContext = React.createContext<GameContextValue>(null!);

interface Props extends Partial<GameContextValue['settings']> {
  children: React.ReactNode;
  levelData: LevelData;
  showCode: boolean;
  onLevelComplete: () => void;
}

export default function Game({
  onLevelComplete,
  levelData,
  movementDuration = 250,
  cameraZoom = 64,
  showCode,
  children
}: Props) {
  const [paused, setPaused] = useState(false);
  const [mapSize, setMapSize] = useState<[number, number]>(() => [1, 1]);
  const [registryById] = useState<GameObjectRegistry>(() => new Map());
  const [registryByName] = useState<GameObjectRegistry>(() => new Map());
  const [registryByXY] = useState<GameObjectRegistry<GameObjectRef[]>>(() => new Map());
  const [registryByLayer] = useState<GameObjectRegistry<GameObjectRef[]>>(() => new Map());
  const [pubSub] = useState(() => createPubSub());
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [gameStore] = useState(() => new Map<string | symbol, any>());
  const [width, height] = [600, 600]; // useWindowSize();

  const storeUtils = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setGameState(key: any, value: any) {
        gameStore.set(key, value);
      },
      getGameState(key: string | symbol) {
        return gameStore.get(key);
      }
    }),
    [gameStore]
  );

  useEffect(() => {
    pubSub.subscribe('finish-level', () => {
      onLevelComplete();
    });
  }, []);

  useEffect(() => {
    return pubSub.subscribe<SceneExitEvent>('scene-exit', () => {
      registryById.clear();
      registryByName.clear();
      registryByXY.clear();
      registryByLayer.clear();
    });
  }, [pubSub, registryById, registryByLayer, registryByName, registryByXY]);

  const registryUtils = useMemo<GameObjectRegistryUtils>(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      registerGameObject(identifier, ref: any) {
        // register by id
        registryById.set(identifier, ref);
        // register by name
        registryByName.set(ref.name, ref);
        // register by x, y
        const { transform } = ref;
        const xy = `${transform.x},${transform.y}`;
        const xyList = registryByXY.get(xy) || [];
        xyList.push(ref);
        registryByXY.set(xy, xyList);
        // register by layer
        const layerList = registryByLayer.get(ref.layer) || [];
        layerList.push(ref);
        registryByLayer.set(ref.layer, layerList);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      unregisterGameObject(identifier, ref: any) {
        // unregister by id
        registryById.delete(identifier);
        // unregister by name
        registryByName.delete(ref.name);
        // unregister by x, y
        const { transform } = ref;
        const xy = `${transform.x},${transform.y}`;
        const xyList = registryByXY.get(xy);
        xyList?.splice(xyList.indexOf(ref), 1);
        // unregister by layer
        const layerList = registryByLayer.get(ref.layer);
        layerList?.splice(layerList.indexOf(ref), 1);
      },
      findGameObjectById(id): any {
        return registryById.get(id);
      },
      findGameObjectByName(name): any {
        return registryByName.get(name);
      },
      findGameObjectsByXY(x, y) {
        return registryByXY.get(`${x},${y}`)?.filter((obj) => !obj.disabled) || [];
      },
      findGameObjectsByLayer(layer) {
        return registryByLayer.get(layer)?.filter((obj) => !obj.disabled) || [];
      },
      findCompoundObjectByXY(x: number, y: number) {
        const layer = Array.from(registryByLayer.values()).find((layer) =>
          layer.find(
            (obj) =>
              obj.sprites.isCompound &&
              obj.sprites.positions.some((pos) => pos.x === x && pos.y === y)
          )
        );
        return layer?.find(
          (obj) =>
            obj.sprites.isCompound &&
            obj.sprites.positions.some((pos) => pos.x === x && pos.y === y)
        );
      }
    }),
    [registryById, registryByLayer, registryByName, registryByXY]
  );

  const contextValue: GameContextValue = {
    settings: {
      movementDuration,
      cameraZoom
    },
    paused,
    setPaused,
    mapSize,
    setMapSize,
    ...storeUtils,
    ...registryUtils,
    ...pubSub
  };

  return (
    <GameContext.Provider value={contextValue}>
      <Box
        component="div"
        sx={{
          position: 'relative',
          userSelect: 'none',
          width: showCode ? 'calc(100vw - 640px)' : 'calc(100vw)',
          height: 'calc(100vh - 64px - 66px)'
        }}
      >
        <ChooseStudentOverlay />
        <Inventory levelData={levelData} />
        <Canvas
          id="game-canvas"
          linear
          flat
          camera={{
            // position: new Vector3(0, 0, 32),
            zoom: cameraZoom,
            near: 0.1,
            far: 44
          }}
          style={{ cursor: 'pointer' }}
          orthographic
          // noEvents
          // gl2
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          gl={{ antialias: false, preserveDrawingBuffer: true }}
          performance={{ min: 0.1 }}
          onContextMenu={(e) => e.preventDefault()}
        >
          {children}
        </Canvas>
      </Box>
    </GameContext.Provider>
  );
}
