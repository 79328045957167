import { Avatar, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

interface AssistantSwitchProps {
  tipActivated: boolean;
  setTipActivated: (value: boolean) => void;
  isExecutionDisabled: boolean;
}

const AvatarSwitch = styled(Switch)(({ theme }) => ({
  width: 72,
  height: 34,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    transitionDuration: '300ms',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-checked': {
      transform: 'translateX(32px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#65B700',
        opacity: 1
      }
    }
  },
  '& .MuiSwitch-thumb': {
    width: 30,
    height: 30,
    backgroundColor: 'transparent',
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  '& .MuiSwitch-track': {
    border: '1px solid #65B700',
    backgroundColor: 'transparent',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    }),
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '14px',
      fontWeight: 'normal',
      fontFamily: 'Silkscreen',
    },
    '&::before': {
      content: '"ON"',
      left: 12,
      color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    '&::after': {
      content: '"OFF"',
      right: 12,
      color: "#65B700"
    }
  }
}));

export default function AssistantSwitch({
  tipActivated,
  setTipActivated,
  isExecutionDisabled
}: AssistantSwitchProps) {
  return (
    <AvatarSwitch
      checked={tipActivated}
      onChange={() => setTipActivated(!tipActivated)}
      disabled={isExecutionDisabled}
      icon={<Avatar alt="Remy Sharp" src="/assets/sprites/sleeping-geek.png" />}
      checkedIcon={<Avatar alt="Remy Sharp" src="/assets/sprites/geek.png" />}
    />
  );
}
