import { createContext, useContext, useRef, useState } from 'react';
import {
  Building,
  Close,
  Collecting,
  Combining,
  Command,
  Open,
  Place,
  Plant,
  Push,
  Speech,
  Writing,
  Reading,
  Watering,
  Asking
} from '../@core/utils/movementHelpers';
import { Position } from '../@core/GameObject';
import { Direction } from '../@core/Moveable';

export type PythonOutput = string;

export type Path =
  | Position
  | Direction[]
  | Speech
  | Building
  | Watering
  | Writing
  | Reading
  | Asking
  | Collecting
  | Open
  | Close
  | Place
  | Plant
  | Push
  | Combining
  | { state: string }
  | { isPointer: boolean };
export interface CommandInQueue {
  command: Command;
  status: 'pending' | 'progress' | 'completed';
  path: Path[];
  id: number;
}

interface OutputContextType {
  isCodeExecuted: boolean;
  setIsCodeExecuted: React.Dispatch<React.SetStateAction<boolean>>;
  returnToStart: boolean;
  setReturnToStart: React.Dispatch<React.SetStateAction<boolean>>;
  resetLevel: boolean;
  setResetLevel: React.Dispatch<React.SetStateAction<boolean>>;
  firstExecution: boolean;
  setFirstExecution: React.Dispatch<React.SetStateAction<boolean>>;
  commandsInQueue: CommandInQueue[];
  setCommandsInQueue: React.Dispatch<React.SetStateAction<CommandInQueue[]>>;
  commandInProgress: number;
  setCommandInProgress: React.Dispatch<React.SetStateAction<number>>;
  resetCommandsQueue: () => void;
  path: Path[];
  setPath: React.Dispatch<React.SetStateAction<Path[]>>;
  isProcessingLocked: React.MutableRefObject<boolean>;
  lastCommandInProgress: number;
  setLastCommandInProgress: React.Dispatch<React.SetStateAction<number>>;
}

const OutputContext = createContext<OutputContextType | null>(null);

export function useOutput(): OutputContextType {
  return useContext(OutputContext) as OutputContextType;
}

export function OutputProvider({ children }: { children: React.ReactNode }) {
  const [isCodeExecuted, setIsCodeExecuted] = useState<boolean>(true);
  const [returnToStart, setReturnToStart] = useState<boolean>(false);
  const [resetLevel, setResetLevel] = useState<boolean>(false);
  const [firstExecution, setFirstExecution] = useState<boolean>(true);
  const [commandsInQueue, setCommandsInQueue] = useState<CommandInQueue[]>([]);
  const [commandInProgress, setCommandInProgress] = useState<number>(0);
  const [lastCommandInProgress, setLastCommandInProgress] = useState<number>(0);
  const [path, setPath] = useState<Path[]>([]);
  const isProcessingLocked = useRef(false);

  const resetCommandsQueue = () => {
    setCommandsInQueue([]);
    setLastCommandInProgress(0);
    setCommandInProgress(0);
    setPath([]);
  };

  const value: OutputContextType = {
    isCodeExecuted,
    setIsCodeExecuted,
    returnToStart,
    setReturnToStart,
    resetLevel,
    setResetLevel,
    firstExecution,
    setFirstExecution,
    commandsInQueue,
    setCommandsInQueue,
    commandInProgress,
    setCommandInProgress,
    resetCommandsQueue,
    path,
    setPath,
    isProcessingLocked,
    lastCommandInProgress,
    setLastCommandInProgress
  };

  return <OutputContext.Provider value={value}>{children}</OutputContext.Provider>;
}
