import { Box, Button } from '@mui/material';
import { Build, HelpOutline, MenuBook, QuestionMark } from '@mui/icons-material';
import LoadCodeModal from './LoadCodeModal';
import React, { useState } from 'react';
import { useGenericModal } from '../context/GenericModalContext';
import CodeBookDrawer from '../pages/codebook/CodeBookDrawer';
import { ChatGPTRequest, ChatgptService, LevelService } from '../client';
import { useOutput } from '../context/OutputContext';
import { useCode } from '../context/CodeContext';
import { Link, useParams } from 'react-router-dom';
import usePyodide from '../hooks/usePyodide';
import useLevels from './useLevels';

export default function GameTopBar() {
  const { courseId, levelId } = useLevels();
  const { setOpenModal: setOpenTipModal, setContent: setContentTipModal } = useGenericModal('tip');
  const { setOpenModal: setOpenHintModal } = useGenericModal('hint');

  const [openCodebook, setOpenCodebook] = useState(false);
  const { isCodeExecuted } = useOutput();
  const { code, setCode } = useCode();
  const { errorMessage } = usePyodide();

  const handleTip = async () => {
    try {
      setContentTipModal('Generating tip...');
      // Use the generated API client to fetch level data
      const res = await LevelService.getLevelDataRouteApiLevelDataCourseSlugSlugGet(
        courseId,
        levelId
      );

      const { tutorial: description, solution } = res;

      let error: string | null = null;

      if (errorMessage) {
        error = errorMessage;
      }

      const chatGPTRequest: ChatGPTRequest = {
        description,
        solution,
        last_prompt: code
      };

      if (error) {
        chatGPTRequest.error_message = error;
      }

      const tipRes = await ChatgptService.generateTipApiChatgptGenerateTipPost(chatGPTRequest);
      if (tipRes.tip) {
        const tipObject = tipRes.tip;
        const tipMessage = tipObject.hint;
        setContentTipModal(tipMessage);
      } else {
        setContentTipModal('Error generating tip. Please try again.');
      }
    } catch (e) {
      console.error('Error fetching level data or generating tip:', e);
      setContentTipModal('Failed to fetch the tip. Please try again later.');
    }
  };
  return (
    <Box
      component="div"
      sx={{
        py: 1,
        px: 1,
        display: 'flex',
        gap: 1,
        justifyContent: 'flex-end'
        // borderBottomWidth: 1,
        // borderBottomStyle: 'solid',
        // borderBottomColor: 'rgba(255, 255, 255, 0.12)'
      }}
    >
      <CodeBookDrawer open={openCodebook} setOpen={setOpenCodebook} />

      <Button
        size="small"
        onClick={() => {
          setOpenHintModal(true);
        }}
        disabled={!isCodeExecuted}
        variant="outlined"
        startIcon={<QuestionMark />}
      >
        Help
      </Button>
      
      {/* deprecated */}
      {/* <Button
        size="small"
        variant="outlined"
        component="span"
        onClick={() => {
          handleTip();
          setOpenTipModal(true);
        }}
        startIcon={<HelpOutline />}
      >
        Tip
      </Button> */}
      <Button
        size="small"
        variant="outlined"
        component="span"
        onClick={() => {
          setOpenCodebook(true);
        }}
        startIcon={<MenuBook />}
      >
        Code book
      </Button>
    </Box>
  );
}
