import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import TipModal from '../components/TipModal';
import ErrorModal from '../components/ErrorModal';
import ChooseStudentModal from '../components/ChooseStudentModal';
import React, { useEffect, useState } from 'react';
import { Ace } from 'ace-builds';
import { useCode } from '../context/CodeContext';
import { useOutput } from '../context/OutputContext';
import MarkdownSection from '../components/MarkdownSection';
import { PlayArrow, QuestionMark, Refresh } from '@mui/icons-material';
import usePyodide from '../hooks/usePyodide';
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { useParams } from 'react-router-dom';
import { ChallengeCompletionStatus, ExecutionType } from '../client';
import { useChallengeBySlug } from '../hooks/useChallengeBySlug';
import useChallengeCompletion from '../hooks/useChallengeCompletion';
import ChallengeCompleteModal from '../components/Challenge/ChallengeCompleteModal';
import { useGenericModal } from '../context/GenericModalContext';
import { useChallenges } from '../hooks/useChallenges';
import { useCredits } from '../hooks/useCredits';
import { useQueryClient } from 'react-query';
import CreditCounter from '../components/Challenge/CreditCounter';
import SignUpCreditsModal from '../components/SignUpCreditsModal';
import UpgradePlanModal from '../components/UpgradePlanModal';
import ChooseStudentOverlay from '../@core/ChooseStudentOverlay';
import { CodeEditor } from '../components/Assistant/CodeEditor';
import { useVirtualAssistantContext } from '../context/VirtualAssistantContext';
import Tooltip from '@mui/material/Tooltip';
import AssistantSwitch from '../components/Assistant/Switcher';
import Cookies from 'js-cookie';

export default function Challenge() {
  const [editor, setEditor] = useState<Ace.Editor>();
  const { code, setCode } = useCode();
  const { setResetLevel: setResetChallenge } = useOutput();
  const [selectedTab, setSelectedTab] = useState(0);

  const [executionResult, setExecutionResult] = useState<string[]>();
  const [isCodeRunning, setIsCodeRunning] = useState(false);
  const [isExecutionSuccessful, setIsExecutionSuccessful] = useState(false);

  const { generateTipChallengeAsync, setIsTipSwitcherActive, isTipSwitcherActive } = useVirtualAssistantContext();

  const { setOpenModal: setOpenChallengeCompleteModal } = useGenericModal('challenge-complete');
  const {
    runScript,
    isLoading: isPyodideLoading,
    errorAnnotations,
    setErrorAnnotations,
    errorMessage
  } = usePyodide();
  const { lng, challengeSlug } = useParams();

  const { data: challenge, isLoading: isChallengeLoading } = useChallengeBySlug(challengeSlug, lng);

  const { data: challenges, isLoading } = useChallenges();

  const { updateChallengeCompletion } = useChallengeCompletion();

  const { data: credits, isLoading: isCreditsLoading } = useCredits(ExecutionType.CHALLENGE);
  const { data: assistantCredits, isLoading: isAssistantCreditsLoading } = useCredits(
    ExecutionType.CHALLENGE_ASSISTANT
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    if (challenge) {
      setCode(challenge.template);
    }
  }, [challenge]);

  useEffect(() => {
    (async () => {
      if (!isExecutionSuccessful) {
        return;
      }
      if (!challengeSlug) {
        return;
      }
      try {
        await updateChallengeCompletion.mutateAsync({
          last_status: ChallengeCompletionStatus.COMPLETE,
          challenge_slug: challengeSlug
        });

        queryClient.invalidateQueries('credits-challenge');
        setOpenChallengeCompleteModal(true);
      } catch (error) {
        console.error('Error updating challenge completion:', error);
      }
    })();
  }, [isExecutionSuccessful]);

  if (!challenge) return null;

  const onCodeExecution = async () => {
    if (!challengeSlug) {
      return;
    }

    try {
      await updateChallengeCompletion.mutateAsync({
        last_status: ChallengeCompletionStatus.IN_PROGRESS,
        last_code_executed: code,
        challenge_slug: challengeSlug
      });
    } catch (error) {
      console.error('Error updating challenge progress:', error);
    }
  };

  const runCode = async () => {
    if (runScript) {
      setIsExecutionSuccessful(false);
      setIsCodeRunning(true);
      try {
        await runScript(code, true);
        await runScript('import json');

        const executions: string[] = [];
        let success = true;

        for (const execution of challenge.executions) {
          const result = await runScript(
            `json.dumps(${challenge.function_name}(*json.loads("""${execution.input}""")))`
          );

          const resultString = result.results;
          executions.push(resultString);

          if (resultString !== execution.output) {
            success = false;
          }
        }

        setExecutionResult(executions);
        setIsCodeRunning(false);
        if (!success) {
          await onCodeExecution();
          queryClient.invalidateQueries('credits-challenge');

          if (isTipSwitcherActive) {
            await generateTipChallengeAsync?.(challenge, errorMessage);
            queryClient.invalidateQueries('credits-challenge_assistant');
          }
        }
        setIsExecutionSuccessful(success);
      } catch (error) {
        if (isTipSwitcherActive) {
          await generateTipChallengeAsync?.(challenge, errorMessage);
          queryClient.invalidateQueries('credits-challenge_assistant');
        }
        console.error('Error executing code:', error);
        setIsExecutionSuccessful(false);
      }

      setIsCodeRunning(false);
    }
  };

  const reset = () => {
    setCode(challenge.template);
    setResetChallenge(true);
  };

  const solution = () => {
    setCode(challenge.solution);
    setResetChallenge(true);
  };

  const onChange = (newValue: string) => {
    setCode(newValue);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const getNextChallenge = () => {
    if (!challenges) return null;
    const currentChallengeIndex = challenges.findIndex((c) => c.slug === challengeSlug);
    if (currentChallengeIndex === -1) return null;
    return challenges[currentChallengeIndex + 1];
  };

  const isExecutionDisabled = isCodeRunning;

  const assistantTitle = !isTipSwitcherActive ? 'Activate assistant' : 'Deactivate assistant';

  const handleActivateTipSwitcher = (value: boolean) => {
    setIsTipSwitcherActive(value);
    Cookies.set('isTipSwitcherActive', String(value));
  };

  return (
    <Box component="div" sx={{ flex: 1, width: '100%', display: 'flex' }}>
      <SignUpCreditsModal />
      <UpgradePlanModal />
      <Box
        component="div"
        sx={{
          flexDirection: 'column',
          overflowY: 'scroll',
          width: 640,
          minWidth: 640,
          maxWidth: '100%',
          paddingLeft: 3,
          paddingRight: 3,
          display: 'flex',
          height: 'calc(100vh - 64px)'
        }}
      >
        <TipModal />
        <ErrorModal />
        <ChallengeCompleteModal
          nextChallenge={getNextChallenge()?.slug}
          currentChallenge={challenge.title}
        />
        <ChooseStudentModal />

        <Box component="div">
          <Box component="div" sx={{ mt: 2 }}>
            <Typography variant="h6" align="center">
              {challenge.title}
            </Typography>
          </Box>
          <Divider sx={{ mt: 2 }} />

          <Box component="div" className="level-data-markdown" dir="auto">
            <MarkdownSection markdown={challenge.description} />
          </Box>
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          position: 'relative',
          userSelect: 'none',
          flex: 1,
          overflow: 'none',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 'calc(100vh - 64px)'
        }}
      >
        <ChooseStudentOverlay />
        <CodeEditor code={code} onChange={onChange} setEditor={setEditor} />
        <Box
          component="div"
          alignItems="center"
          justifyContent="space-between"
          sx={{ display: 'flex', p: 2, pr: 9, pl: 6 }}
        >
          <Box component="div" sx={{ display: 'flex' }}>
            <Button
              onClick={runCode}
              disabled={isExecutionDisabled}
              variant="contained"
              startIcon={!isCodeRunning && <PlayArrow />}
              sx={{ width: '137px' }}
            >
              {!isCodeRunning ? (
                'Run Code'
              ) : (
                <Box
                  component="div"
                  sx={{ display: 'flex', gap: 1, flexDirection: 'row', alignItems: 'center' }}
                >
                  <CircularProgress size={15} />
                  Running
                </Box>
              )}
            </Button>
            <CreditCounter
              isCodeRunning={isCodeRunning}
              credits={credits}
              loading={isCreditsLoading}
            />
          </Box>
          <Box component="div" sx={{ display: 'flex', gap: 2 }}>
            <Stack direction="row">
              <Tooltip title={assistantTitle}>
                <Box component="div">
                  <AssistantSwitch
                    tipActivated={isTipSwitcherActive}
                    setTipActivated={handleActivateTipSwitcher}
                    isExecutionDisabled={isExecutionDisabled}
                  />
                </Box>
              </Tooltip>
              <CreditCounter
                isCodeRunning={isCodeRunning}
                credits={assistantCredits}
                loading={isAssistantCreditsLoading}
              />
            </Stack>
            <Button
              onClick={reset}
              variant="outlined"
              disabled={isExecutionDisabled}
              startIcon={<Refresh />}
            >
              Reset
            </Button>
            {/* <Button
              onClick={solution}
              variant="outlined"
              disabled={isExecutionDisabled}
              startIcon={<QuestionMark />}
            >
              Solution
            </Button> */}
          </Box>
        </Box>

        <Box component="div">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Execution Tabs"
          >
            {challenge.executions.map((execution, index) => (
              <Tab key={index} label={`Execution ${index + 1}`} />
            ))}
          </Tabs>

          <Box
            component="div"
            sx={{
              display: 'flex',
              padding: 2,
              gap: 2,
              flexDirection: 'row',
              alignItems: 'stretch',
              height: 250
            }}
          >
            <Box
              component="div"
              sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 1, overflow: 'scroll' }}
            >
              <Typography variant="subtitle1">Inputs</Typography>
              <Divider />
              {JSON.parse(challenge.executions[selectedTab].input).map((i: any, index: number) => (
                <Box
                  component="div"
                  key={JSON.stringify(i)}
                  sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                >
                  <Typography sx={{ fontFamily: 'monospace' }}>Arg {index + 1}:</Typography>
                  <SyntaxHighlighter
                    style={monokaiSublime}
                    wrapLines={true}
                    wrapLongLines={true}
                    PreTag="div"
                    language="python"
                  >
                    {JSON.stringify(i)}
                  </SyntaxHighlighter>
                </Box>
              ))}
            </Box>

            <Box
              component="div"
              sx={{ flex: 2, display: 'flex', flexDirection: 'column', gap: 1, overflow: 'scroll' }}
            >
              <Box
                component="div"
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  overflow: 'scroll'
                }}
              >
                <Typography variant="subtitle1">Expected Output</Typography>
                <SyntaxHighlighter
                  style={monokaiSublime}
                  customStyle={{ flex: 1 }}
                  PreTag="div"
                  wrapLines={true}
                  wrapLongLines={true}
                >
                  {`${challenge.executions[selectedTab].output}`}
                </SyntaxHighlighter>
              </Box>
              <Box
                component="div"
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  overflow: 'scroll'
                }}
              >
                <Typography variant="subtitle1">Actual Output</Typography>
                <SyntaxHighlighter
                  style={monokaiSublime}
                  customStyle={{ flex: 1 }}
                  PreTag="div"
                  wrapLines={true}
                  wrapLongLines={true}
                >
                  {executionResult ? executionResult[selectedTab] : ' '}
                </SyntaxHighlighter>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
