import * as yup from 'yup';

export const feedbackSchema = yup.object({
  rating: yup.number().required('Rating is required'),
  feedback: yup.string().required('Feedback is required'),
  from_email: yup.string().optional()
});

export const cancelSubscriptionFeedbackSchema = yup.object().shape({
  cancel_subscription_reason: yup.string().required('Please select a reason'),
  feedback: yup.string().when('cancel_subscription_reason', {
    is: (val: string) => val === 'other',
    then: (schema) => schema.required('Feedback is required. Or choose another reason.'),
    otherwise: (schema) => schema.notRequired()
  })
});
