import { useState, useRef, useLayoutEffect } from 'react';
import { WritingEvent } from './Moveable';
import useGameObjectEvent from './useGameObjectEvent';
import waitForMs from './utils/waitForMs';
import { Primitive } from './utils/commandHelper';
import useGame from './useGame';
import useGameObject from './useGameObject';
import {
  calculatePosition,
  GameBackdrop,
  InGamePopUp,
  MessageBox
} from '../components/GameUI/InGamePopUp';
import { Html } from '@react-three/drei';
import { Box } from '@mui/material';
import PrimaryButton from '../components/GameUI/PrimaryButton';

interface WritingPopUpProps {
  mapBounds: { width: number; height: number };
}

export default function WritingPopUp({ mapBounds }: WritingPopUpProps) {
  const [writingMessage, setWritingMessage] = useState<Primitive | null>(null);
  const writingPopUpRef = useRef<HTMLDivElement>(null);
  const { findGameObjectByName } = useGame();
  const { getComponent } = useGameObject();
  const moveableRef = getComponent('Moveable');

  useGameObjectEvent<WritingEvent>('writing', ({ message, isWriting }) => {
    if (!message) return;
    waitForMs(0).then(() => {
      setWritingMessage(message as string);
    });
  });

  const handleClosePopup = () => {
    setWritingMessage(null);
    moveableRef.setIsWriting(false);
  };

  useLayoutEffect(() => {
    if (writingMessage) {
      calculatePosition(mapBounds, writingPopUpRef, findGameObjectByName('player'));
    }
  }, [writingMessage]);

  return (
    <>
      {writingMessage && (
        <Html style={{ zIndex: '1300', cursor: 'default' }}>
          <GameBackdrop open={true} />
          <InGamePopUp popUpRef={writingPopUpRef}>
            <Box
              component="div"
              sx={{
                px: 2,
                pt: 2,
                pb: 1,
                display: 'flex',
                height: '100%',
                width: '100%',
                flexDirection: 'column'
              }}
            >
              <MessageBox>
                <Box component="div">
                  {typeof writingMessage === 'string' ? (
                    writingMessage.split('\n').map((line, index) => <div key={index}>{line}</div>)
                  ) : (
                    <div>{writingMessage}</div>
                  )}
                </Box>
              </MessageBox>
              <PrimaryButton onClick={handleClosePopup} sx={{ mt: 2 }} size="small">
                Close
              </PrimaryButton>
            </Box>
          </InGamePopUp>
        </Html>
      )}
    </>
  );
}
