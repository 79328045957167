import { Box } from '@mui/material';
import { LottieRefCurrentProps, useLottie } from 'lottie-react';
import CreditHeartAnimation from '../resources/animations/credit-heart.json';
import React, { useEffect } from 'react';

interface CreditHeartAnimationViewProps {
  play: boolean;
}

const lottieStyle: { [key: string]: unknown } = {
  zIndex: 1400,
  maxWidth: '36px',
  height: '36px'
};

const CreditHeartAnimationView = ({ play }: CreditHeartAnimationViewProps) => {
  const lottieRef = React.useRef<LottieRefCurrentProps>(null);
  const { View: LottieView, goToAndPlay } = useLottie({
    animationData: CreditHeartAnimation,
    loop: false,
    autoplay: true,
    style: lottieStyle,
    lottieRef
  });

  useEffect(() => {
    if (!play) return;

    goToAndPlay(0);
  }, [play]);

  return <Box component="div">{LottieView}</Box>;
};

export default CreditHeartAnimationView;
