import { useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';

import AceEditor from 'react-ace';
import { Ace } from 'ace-builds';

import { useVirtualAssistantContext } from '../../context/VirtualAssistantContext';
import { useCode } from '../../context/CodeContext';
import CodeAdderAssistant from './CodeAdder';

interface CodeEditorProps {
  code: string;
  onChange: (value: string) => void;
  setEditor: (editor: Ace.Editor) => void;
}

const DEFAULT_LINE_HEIGHT = 22;

export const CodeEditor = ({ code, onChange, setEditor }: CodeEditorProps) => {
  const [tooltip, setTooltip] = useState({
    visible: false,
    text: '',
    x: 0,
    y: 0
  });
  const [tipLineNumber, setTipLineNumber] = useState(0);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<AceEditor>(null);

  const { tip, tipVisible, setTipVisible } = useVirtualAssistantContext();
  const { setCode } = useCode();

  useEffect(() => {
    onCursorChange();
  }, [tip]);

  useEffect(() => {
    const editor = editorRef.current?.editor;
    if (editor) {
      editor.session.on('changeScrollTop', handleScroll);
      return () => {
        editor.session.off('changeScrollTop', handleScroll);
      };
    }
  }, [tooltip.y]);

  const onCursorChange = () => {
    if (!tip) return;
    const lineNumber = tip.line_number || 0;
    setTipLineNumber(tip.line_number || 0);

    if (!editorRef.current) return;
    const editor = editorRef.current.editor;
    editor.resize(true);
    editor.renderer.setScrollMargin(0, 300, 0, 0);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    editor.renderer.scrollToLine(lineNumber, true, true, () => {});
    editor.gotoLine(lineNumber + 1, 0, true);

    setTimeout(() => {
      // wait for the editor to scroll to the line
      const scrollTop = editor.getSession().getScrollTop();
      const coords = editor.renderer.textToScreenCoordinates(lineNumber, 0);
      setTipVisible(true);
      setTooltip({
        visible: true,
        text: '',
        x: coords.pageX + 50,
        y: lineNumber * DEFAULT_LINE_HEIGHT - scrollTop - DEFAULT_LINE_HEIGHT
      });
    }, 500);
  };

  const handleScroll = () => {
    if (!editorRef.current || !tooltipRef.current) return;
    const editor = editorRef.current.editor;
    const scrollTop = editor.getSession().getScrollTop();
    const top = tipLineNumber * 22 - scrollTop - 22;
    tooltipRef.current.style.top = `${top}px`;
  };

  const insertCode = (resultedCode: string) => {
    setCode(resultedCode);
    setTipVisible(false);
    setTooltip({ visible: false, text: '', x: 0, y: 0 });
  };

  return (
    <Stack
      sx={{
        position: 'relative',
        flex: 1
      }}
    >
      <AceEditor
        ref={editorRef}
        onLoad={setEditor}
        width="100%"
        minLines={10}
        fontSize={14}
        mode="python"
        theme="monokai"
        onChange={onChange}
        value={code}
        name="game-editor"
        style={{ flex: 1 }}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          showLineNumbers: true,
          tabSize: 4
        }}
        editorProps={{ $blockScrolling: true }}
      />
      {tipVisible && (
        <CodeAdderAssistant
          sx={{
            position: 'absolute',
            top: `${tooltip.y}px`,
            left: `${tooltip.x - 500}px`,
            zIndex: 1200,
          }}
          onClick={insertCode}
        />
      )}
    </Stack>
  );
};
