import GameObject, { GameObjectProps } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useSceneManager from '../@core/useSceneManager';
import useGame from '../@core/useGame';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import { DidCollectEvent } from '../@core/Collectable';
import waitForMs from '../@core/utils/waitForMs';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import { useRef } from 'react';
import {
  Data,
  checkDataIsEqual,
  convertToPrimitive,
  parseJson
} from '../@core/utils/commandHelper';
import { useCommandError } from '../hooks/useCommandError';
import { XMARK_LIST } from '../@core/utils/xmarkList';

interface Message {
  message: Data;
}

function WriteScript() {
  const xmarkState = useRef(false);
  const { getRef } = useGameObject();
  const { publish, findGameObjectByName } = useGame();

  const { levelData, setLevelData } = useSceneManager();
  const { apiLevelData } = useApiLevelData();
  const { triggerError } = useCommandError();

  useGameObjectEvent<InteractionEvent>('interaction', async ({ obj, step }) => {
    const writeStep = step as Message;

    const { message } = writeStep;
    if (!message) return;

    if (!apiLevelData) return;

    const { levelGoals } = apiLevelData;
    const { items } = apiLevelData;

    const writeItems = items?.filter((item) => item.action === 'write') || [];
    const positionWritable = writeItems.find(
      (item) => item.position[0] === obj.transform.x && item.position[1] === obj.transform.y
    );

    const levelWritables = levelGoals?.filter(
      (goal) =>
        goal.action === 'write' &&
        goal.position?.[0] === obj.transform.x &&
        goal.position?.[1] === obj.transform.y
    );

    const levelWritable =
      levelWritables.length > 1
        ? levelWritables.find((goal) => checkDataIsEqual(goal.data, message))
        : levelWritables[0];

    if (!positionWritable) {
      triggerError(UNEXPECTED_ERRORS.write.position);
      return false;
    }

    if (!levelWritable) {
      triggerError(UNEXPECTED_ERRORS.write.wrongMessage);
      return false;
    }

    if (!checkDataIsEqual(levelWritable.data, message)) {
      // Uncomment the following lines to debug the wrong message issue
      // console.log('levelWritable.data', levelWritable.data);
      // console.log('message', message);
      triggerError(UNEXPECTED_ERRORS.write.data(levelWritable?.data));
      return false;
    }

    const playerObject = findGameObjectByName('player');
    const playerMoveable = playerObject?.getComponent('Moveable');
    await playerMoveable.write({ message: convertToPrimitive(message) });

    const newLevelData = levelData.map((item) => {
      if (
        XMARK_LIST.includes(item.type || '') &&
        item.position?.[0] === obj.transform.x &&
        item.position?.[1] === obj.transform.y &&
        item.action === 'write' &&
        checkDataIsEqual(parseJson(item.data), message)
      ) {
        return { ...item, score: (item.score += 1) };
      }
      return item;
    });

    setLevelData(newLevelData);

    publish<DidCollectEvent>('did-collect', newLevelData);

    xmarkState.current = !xmarkState.current;

    if (xmarkState.current) {
      getRef().setDisabled(true);
    }
    return waitForMs(400);
  });

  return null;
}

interface ItemWriteProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemWrite({ props, state, spriteData }: ItemWriteProps) {
  const name = `${state}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Interactable type="write" />
      <WriteScript />
    </GameObject>
  );
}
