import { useState, useRef, useLayoutEffect } from 'react';
import { ReadingEvent } from './Moveable';
import useGameObjectEvent from './useGameObjectEvent';
import waitForMs from './utils/waitForMs';
import { Primitive } from './utils/commandHelper';
import useGame from './useGame';
import useGameObject from './useGameObject';
import { LevelData } from '../client';
import { Box } from '@mui/material';
import PrimaryButton from '../components/GameUI/PrimaryButton';
import { Html } from '@react-three/drei';
import {
  calculatePosition,
  GameBackdrop,
  InGamePopUp,
  MessageBox
} from '../components/GameUI/InGamePopUp';

interface ReadingPopUpProps {
  mapBounds: { width: number; height: number };
  levelData: LevelData;
}

export default function ReadingPopUp({ mapBounds, levelData }: ReadingPopUpProps) {
  const [readingMessage, setReadingMessage] = useState<Primitive | null>(null);
  const [backdropOpen, setBackdropOpen] = useState<boolean>(false);
  const readingPopUpRef = useRef<HTMLDivElement>(null);
  const { findGameObjectByName } = useGame();
  const { getComponent } = useGameObject();
  const moveableRef = getComponent('Moveable');

  useGameObjectEvent<ReadingEvent>('reading', ({ isReading }) => {
    if (!isReading) return;
    waitForMs(0).then(() => {
      const playerObject = findGameObjectByName('player');
      const playerX = playerObject.transform.x;
      const playerY = playerObject.transform.y;
      checkForReadableItem(playerX, playerY);
    });
  });

  const checkForReadableItem = (playerX: number, playerY: number) => {
    if (!levelData?.items) return;
    const readableItem = levelData.items.find(
      (item) =>
        item.action === 'read' && item.position[0] === playerX && item.position[1] === playerY
    );

    if (readableItem) {
      setReadingMessage(readableItem.data);
      setBackdropOpen(true);
    } else {
      setReadingMessage(null);
    }
  };

  const handleClosePopup = () => {
    setReadingMessage(null);
    setBackdropOpen(false);
    moveableRef.setIsReading(false);
  };

  useLayoutEffect(() => {
    if (readingMessage) {
      calculatePosition(mapBounds, readingPopUpRef, findGameObjectByName('player'));
    }
  }, [readingMessage]);

  return (
    <>
      {readingMessage && (
        <Html style={{ zIndex: '1300', cursor: 'default' }}>
          <GameBackdrop open={backdropOpen} />
          <InGamePopUp popUpRef={readingPopUpRef}>
            <Box
              component="div"
              sx={{
                px: 2,
                pt: 2,
                pb: 1,
                display: 'flex',
                height: '100%',
                width: '100%',
                flexDirection: 'column'
              }}
            >
              <MessageBox>
                <Box component="div">
                  {typeof readingMessage === 'string' ? (
                    readingMessage.split('\n').map((line, index) => <div key={index}>{line}</div>)
                  ) : (
                    <div>{readingMessage}</div>
                  )}
                </Box>
              </MessageBox>
              <PrimaryButton onClick={handleClosePopup} sx={{ mt: 2 }} size="small">
                Close
              </PrimaryButton>
            </Box>
          </InGamePopUp>
        </Html>
      )}
    </>
  );
}
